import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Upload, Icon, message, Input } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
const { Dragger } = Upload;

/* STORES */
let FormStore;
@inject("formStore")
@observer
class DinamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyError: null
    };
    this.properties = {};
    FormStore = this.props.formStore;
  }

  validation(input, element, value, validationType, i) {

    this.setState({
      keyError: i
    });
    FormStore.modelObject[input.dataIndex] = value;
    FormStore.generalEroor = "";

    if (value.length !== 0 || value !== "") {
      element.required = false;
    } else {
      element.required = true;
    }

    for (let i = 0; i < validationType.length; i++) {
      switch (validationType[i]) {
        case "Required":
          FormStore.Required(value);
          break;
        case "TextLength":
          FormStore.TextLength(value);
          break;
        case "Email":
          FormStore.Email(value);
          break;
        case "Phone":
          FormStore.Phone(value);
          break;
        case "Date":
          FormStore.Date(value);
          break;
      }
    }
  }

  validationError(validationType) {
    let formError;
    for (let i = 0; i < validationType.length; i++) {
      switch (validationType[i]) {
        case "Required":
          formError = FormStore.errorRequired;
          break;
        case "TextLength":
          formError = FormStore.errorTextLength;
          break;

        case "Email":
          formError = FormStore.errorEmail;
          break;

        case "Phone":
          formError = FormStore.errorPhone;
          break;

        case "Date":
          formError = FormStore.errorDate;
          break;
      }
    }

    return formError;
  }

  /* EVENTS */
  render() {
    let { model, columns, labels, defualtValues } = this.props;
    let { keyError } = this.state;
    return (
      <div>
        {columns.map((input, i) =>
          input.adding ? (
            input.addRender !== undefined ? (
              <div>
                {labels ? (
                  <label className="full bold">{input.title}</label>
                ) : null}
                {input.addRender}
              </div>
            ) : (
              <div>
                {labels ? (
                  <label className="full bold">{input.title}</label>
                ) : null}
                <Input
                  className="marginTop10"
                  type={input.type}
                  required={input.required ? "required" : null}
                  key={i}
                  disabled={input.disabled ? true : false}
                  onChange={e =>
                    this.validation(
                      input,
                      e.target,
                      e.target.value,
                      input.validation,
                      i
                    )
                  }
                  placeholder={input.title}
                />
                {keyError === i ? (
                  <div className="error">
                    {this.validationError(input.validation, i)}
                  </div>
                ) : null}
              </div>
            )
          ) : null
        )}

        <div className="error">{FormStore.generalEroor}</div>
      </div>
    );
  }
}
export default withRouter(DinamicForm);
