import React from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { Row, Col, Tag, Icon, Card, Select } from "antd";
import { inject, observer } from "mobx-react";
import TableScreen from "../../components/table/main";
import Moment from "react-moment";
import "moment/locale/he";
const EditableContext = React.createContext();
const { Option } = Select;
/* STORES */
let Dashboard;
@inject("dashboard")
@observer
class DashboardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editingKey: "",
      enters: "0",
      sellse: "0",
      contact: "0",
      openReports: "0",
    };
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    Dashboard.getDashboardInfo().then((results) => {
      for (let i = 0; i < results.length; i++) {
        switch (results[i]._id) {
          case "כניסה":
            this.setState({ enters: results[i].count });
            break;
          case "לחיצה בעמוד תשלום":
            this.setState({ sellse: results[i].count });
            break;
          case "יצירת קשר עם יזם":
            this.setState({ contact: results[i].count });
            break;
          case `פתיחת דו"ח`:
            this.setState({ openReports: results[i].count });
            break;
        }
      }
    });
  }

  isEditing = (record) => record === this.state.editingKey;

  render() {
    return (
      <div className={"full-height-fotter events dashboard-table"}>
        <div className="conatiner-padding">
          <div className="f1 black">דאשבורד</div>

          <Row gutter="15" className="marginHor20">
            <Col span={6}>
              <Card>
                <p className="bold f4">פתיחת דו"חות</p>
                <p className="bold f1">{this.state.openReports}</p>
                <Icon
                  type="snippets"
                  className="f4 white b-green  padding10 circle"
                  style={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: "25px",
                    left: "15px",
                  }}
                />
              </Card>
            </Col>

            <Col span={6}>
              <Card>
                <p className="bold f4">יצירת קשר עם יזם</p>
                <p className="bold f1">{this.state.contact}</p>
                <Icon
                  type="phone"
                  className="f4 white b-green  padding10 circle"
                  style={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: "25px",
                    left: "15px",
                  }}
                />
              </Card>
            </Col>

            <Col span={6}>
              <Card>
                <p className="bold f4">דרישות תשלום</p>
                <p className="bold f1">{this.state.sellse}</p>
                <Icon
                  type="rise"
                  className="f4 white b-green  padding10 circle"
                  style={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: "25px",
                    left: "15px",
                  }}
                />
              </Card>
            </Col>

            <Col span={6}>
              <Card>
                <p className="bold f4">כניסות</p>
                <p className="bold f1">{this.state.enters}</p>
                <Icon
                  type="user"
                  className="f4 white b-green  padding10 circle"
                  style={{
                    borderRadius: "50px",
                    position: "absolute",
                    top: "25px",
                    left: "15px",
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
        <div className="f1 black paddingVer40">אירועים</div>
        <TableScreen
          model={"events"}
          columns={[
            {
              title: "פרטי משתמש",
              dataIndex: "UserName",
              editable: true,
              adding: false,
              image: true,
              type: "text",
              validation: ["Required"],
            },
            {
              title: "פעילות",
              dataIndex: "Message",
            },
            {
              title: "שם הפרויקט",
              dataIndex: "ProgetcName",
              search: true,
            },

            {
              title: "מספר הנכס",
              dataIndex: "AssetName",
              search: true,
            },
            {
              title: "תאריך",
              dataIndex: "timestamp",
              render: (timestamp) => <Moment fromNow>{timestamp}</Moment>,
            },
            {
              title: "תגובה",
              dataIndex: "users",
              render: (users) =>
                users !== undefined && users.length > 0 ? (
                  <Select defaultValue="def" style={{ width: 220 }}>
                    <Option value="def">בחר תגובה</Option>

                    <Option value="email">
                      <a
                        className="full"
                        target="_blank"
                        href={`mailto:${users[0].Email}`}
                      >
                        שליחת אימייל
                      </a>
                    </Option>
                    <Option value="whatapp">
                      <a
                        className="full"
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=${users[0].contactPhone}&text=שלום+אני+יוצר+איתך+קשר+דרך+האפליקצייה+של+עמינח+שטיינמץ`}
                      >
                        שליחת וואטסאפ
                      </a>
                    </Option>
                    <Option value="hone">
                      <a
                        className="full"
                        target="_blank"
                        href={`callto:${users[0] !== undefined ? users[0].contactPhone : null
                          }`}
                      >
                        טלפון
                      </a>
                    </Option>
                  </Select>
                ) : null,
            },
          ]}
        />
        )
      </div>
    );
  }
}

export default withRouter(DashboardContent);
