import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Card, Button } from "antd";
import { inject, observer } from "mobx-react";
/* STYLES */
import "./main.css";
import Contact from "../../components/contact/main";
/* STORES */
let MainStore;
@inject("mainStore")
@observer
class CardAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true,
      data: [],
      showContactModal: false
    };

    MainStore = this.props.mainStore;
  }

  /* EVENTS */
  showDescription() {
    this.setState({
      display: !this.state.display
    });
  }

  showContactModal(hash) {
    MainStore.showContactModalFunc(hash);
  }

  render() {
    let { hash, image, title, secoundery, decription } = this.props;
    return (
      <div className="CardAsset marginHor20">
        <Card className="no-padding">
          <Row>
            <Col md={14} xs={24} className="paddingVer10">
              <div className={"f3 bold"}>{title}</div>
              <div className={"f4"}>{secoundery}</div>
              <Button
                className="br-blue fright blue marginHor20 "
                style={{
                  width: "100%",
                  height: "40px",
                  margin: "15px auto",
                  display: "block"
                }}
                onClick={e => this.showContactModal(hash)}
              >
                צור קשר עם היזם
              </Button>
            </Col>
            <Col md={10} xs={24}>
              <img src={image} className="imageFull"></img>
            </Col>
            <Col className="marginTop10" span={24}>
              <div
                onClick={e => this.showDescription()}
                className="bold full fright link"
              >
                מידע על הפרויקט:
              </div>
              <div
                className={"bold full fright"}
                style={
                  this.state.display
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {decription}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
export default withRouter(CardAsset);
