import React from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { Popconfirm, Button, Select, Icon } from "antd";
import { inject, observer } from "mobx-react";
import Moment from "react-moment";
import TableScreen from "../../components/table/main";

const { Option } = Select;

const EditableContext = React.createContext();

let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  handleChange(dataIndex, value) {
    FormStore.modelObject[dataIndex] = value;
  }

  isEditing = (record) => record === this.state.editingKey;

  render() {
    // FormStore.modelObject["Role"] = "רוכש";
    return (
      <div className={" full-height-fotter"}>
        <TableScreen
          model={"users"}
          columns={[
            {
              title: "",
              dataIndex: "_id",
              adding: false,
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "שם פרטי",
              dataIndex: "FirstName",
              adding: true,
              search: true,
              type: "text",
              required: true,
              validation: ["TextLength"],
            },
            {
              title: "שם משפחה",
              dataIndex: "LastName",
              adding: true,
              search: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "אימייל",
              dataIndex: "Email",
              search: true,
              adding: true,
              required: true,
              type: "email",
              validation: ["Email"],
            },
            {
              title: "טלפון",
              dataIndex: "PhoneNumber",
              adding: true,
              type: "phone",
              validation: [, "Phone"],
            },
            {
              title: "תפקיד",
              dataIndex: "Role",
              editable: true,
              search: true,
              adding: true,
              render: (Role) => {
                switch (Role) {
                  case 1:
                    return "רוכש";
                    break;

                  case 2:
                    return "יזם";
                    break;

                  case 3:
                    return "נציגות";
                    break;

                  case 4:
                    return "מנהל";
                    break;
                }
              },
              addRender: (
                <Select
                  onChange={(e) => this.handleChange("Role", e)}
                  placeholder="תפקיד"
                  className="full marginTop10 textRight"
                >
                  <Option value="1">רוכש</Option>
                  <Option value="2">יזם</Option>
                  <Option value="3">נציגות</Option>
                  <Option value="4">מנהל</Option>
                </Select>
              ),
              type: "text",
              validation: [, "TextLength"],
            },
            {
              title: "נכסים",
              dataIndex: "assets",
              adding: false,
              render: (assets) => (
                <div onClick={(e) => Dashboard.showPup("assets")}>
                  <Icon
                    onClick={(e) =>
                      assets && assets.length > 0
                        ? (Dashboard.assets = assets)
                        : (Dashboard.assets = [])
                    }
                    type="unordered-list"
                    className="full marginCenter f1"
                  />
                </div>
              ),
              type: "text",
              validation: [""],
            },
            {
              title: "התווסף בתאריך",
              dataIndex: "timestamp",
              render: (timestamp) => (
                <Moment format="DD/MM/YYYY">{timestamp}</Moment>
              ),
              adding: false,
            },

            {
              title: "עריכה",
              dataIndex: "edit",
              render: (timestamp) => (
                <div onClick={(e) => (MainStore.showDrawerModal = true)}>
                  עריכה
                </div>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(Users);
