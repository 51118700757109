import { Select, Tag } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import TableScreen from "../../components/table/main";

const { Option } = Select;

/* STORES */
let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class PaymentsReceived extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: "",
      assets: [],
      pageNumber: 1,
    };
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    MainStore.getAllByModelAndByUser("assets", {}).then((results) => {
      this.setState({ assets: results });
    });
  }

  paginataeAssets(e) {
    e.persist();

    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      MainStore.getAllByModelAndByUser(
        "assets",
        {},
        this.state.pageNumber
      ).then((results) => {
        if (results.length > 1) {
          this.setState({
            assets: [...this.state.assets, ...results],
            pageNumber: this.state.pageNumber + 1,
          });
        }
      });
    }
  }

  handleChange(dataIndex, value) {
    FormStore.modelObject[dataIndex] = value;
  }

  handleChangeMulti(value) {
    value.map(function (item, i) {
      Object.keys(item).forEach(function (key) {
        FormStore.modelObject[key] = item[key];
      });
    });
  }

  showPup(popTtype, bollean) {
    MainStore.actionButtonShow = bollean;
    Dashboard.showPup(popTtype);
  }

  render() {
    // FormStore.modelObject["CashCredit"] = "מזומן";
    // FormStore.modelObject["InBudget"] = "כן";
    let { assets } = this.state;
    return (
      <div className={"full-height-fotter"}>
        <TableScreen
          model={"paymentsReceived"}
          columns={[
            {
              title: "",
              dataIndex: "_id",
              adding: false,
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "שם הפרויקט",
              dataIndex: "ProjectName",
              type: "text",
              required: true,
              search: true,
              validation: ["TextLength"],
            },

            {
              title: "נכס",
              dataIndex: "ProgectHash",
              display: false,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
              addRender: (
                <Select
                  onChange={(e) => this.handleChangeMulti(e)}
                  placeholder="נכס"
                  className="full marginTop10 textRight"
                  onPopupScroll={(e) => this.paginataeAssets(e)}
                >
                  {assets.map((item, i) => (
                    <Option
                      value={[
                        (i: { ProgectId: item.Hash }),
                        { AssetHash: item.Hash },
                        { ProjectName: item.ProjectName },
                        { City: item.City },
                        { Street: item.Street },
                        { StreetNumber: item.StreetNumber },
                        { ApartmentNumber: item.ApartmentNumber },
                      ]}
                    >
                      <span className="bold">{item.ProjectName}: </span>
                      {`${item.City} ${item.Street} ${item.StreetNumber} דירה ${item.ApartmentNumber}`}
                    </Option>
                  ))}
                </Select>
              ),
            },

            {
              title: "עיר",
              dataIndex: "City",
              type: "text",
              search: true,
              required: true,
              validation: ["TextLength"],
            },
            {
              title: "רחוב",
              dataIndex: "Street",
              type: "text",
              search: true,
              required: true,
              validation: ["TextLength"],
            },
            {
              title: "מספר רחוב",
              dataIndex: "StreetNumber",
              type: "number",
              required: true,
              validation: [""],
            },
            // {
            //   title: "קומה",
            //   dataIndex: "ApartmentFloor",

            //   type: "number",
            //   required: true,
            //   validation: [""]
            // },
            {
              title: "מספר דירה",
              dataIndex: "ApartmentNumber",
              type: "number",
              required: true,
              search: true,
              validation: [""],
            },

            {
              title: "סכום ששולם",
              dataIndex: "PaymentAmount",
              editable: true,
              adding: true,
              type: "number",
              required: true,
              render: (number) => Dashboard.numberWithCommas(number),
              validation: [""],
            },

            {
              title: "סעיף תשלום",
              dataIndex: "PaymentSection",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "בתקציב",
              dataIndex: "InBudget",
              editable: true,
              adding: true,
              type: "text",
              required: true,
              validation: [""],
              addRender: (
                <Select
                  onChange={(e) => this.handleChange("InBudget", e)}
                  placeholder="בתקציב?"
                  className="full marginTop10 textRight"
                >
                  <Option value="כן">כן</Option>
                  <Option value="לא">לא</Option>
                </Select>
              ),
              render: (InBudget) => (
                <Tag color="blue" color={InBudget === "כן" ? "green" : "blue"}>
                  {InBudget}
                </Tag>
              ),
            },

            {
              title: "עריכה",
              dataIndex: "edit",
              render: (timestamp) => (
                <div onClick={(e) => (MainStore.showDrawerModal = true)}>
                  עריכה
                </div>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(PaymentsReceived);
