import React from "react";
import { inject, observer } from "mobx-react";
import "./Auth.scss";
import axios from "axios";
import { Input, Button } from "antd";

axios.defaults.withCredentials = true;
let MainStore;
let AuthStore;
@inject("mainStore", "authStore")
@observer
class Login extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      serverError: "",
      loginCode: "",
      typeCode: "",
      user: {}
    };
    AuthStore = this.props.authStore;
    MainStore = this.props.mainStore;
  }


  Login() {
    AuthStore.LoginWithPhone()
  }

  resendPassword(user) {
    AuthStore.resendPassword(user).then((result) => {
      this.setState({
        loginCode: result.code,
        user: result.user,
        serverError: result.message,
      });
    });
  }

  checkValidCode= async(e)=> {

    const user = await AuthStore.validateOtp(this.state.typeCode); 
    debugger
    if (user) {
      localStorage.setItem("login", true);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("t_check", user.Role);

      MainStore.newEvent({
        UserHash: user.Hash,
        UserName: user.FirstName + " " + user.LastName,
        Type: "כניסה",
        Message: "כניסה",
      }).then((results) => {
      });

      window.location.href = "/";
    } else {
      this.setState({
        serverError: "סיסמא לא נכונה",
      });
    }
  }

  render() {
    return (
      <div
        className="background-image b-cover"
        style={{
          backgroundImage: `url(/img/back.png)`,
        }}
      >
        <div className="container auth_container">
          <div className="login  scroll padding20 b-gray">
            <img className="full" src="/img/logo.png"></img>
            {!AuthStore.messageSent ? (
              <div className="marginTop20">
                <div className="error red textCenter bold f3">
                  {this.state.serverError}
                </div>
                <div className="f4 bold textCenter">התחברות ללא סיסמא</div>
                <Input
                  className="marginTop20"
                  type="phone"
                  name="phone"
                  placeholder="מספר טלפון"
                  onChange={(e) =>
                    AuthStore.ValidationPhoneNumber(e.target.value)
                  }
                  // value={values.email}
                />

                <div className="error">{AuthStore.errorPhone}</div>

                <Button
                  className="full marginTop20 br-blur b-blue white"
                  type="submit"
                  // disabled={AuthStore.formValid}
                  onClick={(e) => this.Login()}
                >
                  כניסה
                </Button>
              </div>
            ) : (
              <div className="marginTop20">
                <div className="f4 bold textCenter">
                  נשלח קוד למספר הטלפון הרשום במערכת
                </div>

                <div className="error red textCenter bold f3">
                  {this.state.serverError}
                </div>
                <Input
                  className="marginTop20"
                  type="text"
                  placeholder="****"
                  onChange={(e) => this.setState({ typeCode: e.target.value })}
                  value={this.state.typeCode}
                />

                <Button
                  className="full marginTop20 br-blur b-blue white"
                  type="submit"
                  onClick={(e) => this.checkValidCode(e)}
                >
                  התחברות
                </Button>

                <div
                  onClick={(e) => AuthStore.resendPassword('0548189080')}
                  className="blue link f4 marginTop10 cursor"
                >
                  שלח סיסמא פעם נוספת
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
