import { action, observable, toJS } from "mobx";
import axios from "axios";
import md5 from "md5";

axios.defaults.withCredentials = true;
axios.defaults.crosdomain = true;

export default class FormStore {
  @observable formFiled = {};
  @observable modelObject = {};

  // SELECTED ROWS

  @observable HashArray = "";
  @observable selectedRows = {};

  // VALIDATION
  @observable formValid = false;
  @observable generalEroor = "";

  //   required
  @observable errorRequired = "";

  //   text
  @observable errorTextLength = "";

  //   email
  @observable errorEmail = "";

  //   phone
  @observable errorPhone = "";

  //   date
  @observable errorDate = "";

  //   text
  @action TextLength(value, number = 2) {
    if (value.length >= number) {
      return (this.errorTextLength = "");
    } else {
      return (this.errorTextLength = `אנא הזן לפחות ${number} תווים`);
    }
  }

  //   email
  @action Email(value) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase())) {
      return (this.errorEmail = "");
    } else {
      return (this.errorEmail = "נא לרשום אימייל תקין");
    }
  }

  //   phone
  @action Phone(value) {
    var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.match(re)) {
      return (this.errorPhone = "");
    } else {
      return (this.errorPhone = "הזנת מספר טלפון לא חוקי");
    }
  }

  //   date
  @action Date(value) {
    var re = /\d+\/\d+\/\d{4}/;

    if (re.test(value)) {
      return (this.errorDate = "");
    } else {
      return (this.errorDate = "נא להכניס תאריך בפורמט הבא: dd/mm/yyyyy");
    }
  }

  @action addAdminDateOnChangeDate(dataIndex, date, dateString) {
    this.modelObject[dataIndex] = dateString;
  }

  // add newrow to table dinamic
  @action uploadByModelAndByUser(model) {
    // Hash taging uniq tables

    if (model === "progects") {
      let Hash = md5(
        this.modelObject.ProjectName +
          this.modelObject.City +
          this.modelObject.Street +
          this.modelObject.StreetNumber
      );
      this.modelObject.Hash = Hash;
    } else if (model === "assets") {
      let Hash = md5(
        this.modelObject.City +
          this.modelObject.Street +
          this.modelObject.StreetNumber +
          this.modelObject.ApartmentFloor +
          this.modelObject.ApartmentNumber
      );
      this.modelObject.Hash = Hash;
    } else if (model === "users") {
      let Hash = md5(this.modelObject.Email + this.modelObject.PhoneNumber);

      this.modelObject.Hash = Hash;
      this.modelObject.AssetArrayHash = [];
    } else if (model === "paymentRequirements") {
      let Hash = md5(
        this.modelObject.paymentID +
          this.modelObject.City +
          this.modelObject.Street +
          this.modelObject.StreetNumber +
          this.modelObject.ApartmentFloor +
          this.modelObject.ApartmentNumber
      );

      this.modelObject.Hash = Hash;
    } else if (model === "deposits") {
      let Hash = md5(
        this.modelObject.paymentID +
          this.modelObject.City +
          this.modelObject.Street +
          this.modelObject.StreetNumber +
          this.modelObject.ApartmentFloor +
          this.modelObject.ApartmentNumber
      );

      this.modelObject.Hash = Hash;
    } else if (model === "paymentsReceived") {
      let Hash = md5(
        this.modelObject.paymentID +
          this.modelObject.City +
          this.modelObject.Street +
          this.modelObject.StreetNumber +
          this.modelObject.ApartmentFloor +
          this.modelObject.ApartmentNumber
      );

      this.modelObject.Hash = Hash;
    }

    return new Promise((resolve, reject) => {
      let required = document.querySelectorAll("[required]");

      this.generalEroor = "";
      if (
        this.errorRequired == "" &&
        this.errorTextLength == "" &&
        this.errorEmail == "" &&
        this.errorPhone == "" &&
        this.errorDate == ""
      ) {
        fetch(`${global.apiUrl}/upload/add`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: model,
            modelObject: this.modelObject,
          }),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            
            return resolve(responseJson);
          })
          .catch((error) => {
            return reject(error);
          });
      } else {
        return reject("no");
      }
    });
  }

  // add newrow to table dinamic
  @action deleteModelByHash(model) {
    // Hash taging uniq tables
    if (this.deleteRows === "delete") {
     
      return new Promise((resolve, reject) => {
        fetch(`${global.apiUrl}/upload/delete`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: model,
            HashArray: this.HashArray,
          }),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            return resolve(responseJson);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    }
  }
}
