import React from "react";
import { withRouter, Route, Link } from "react-router-dom";
import TableScreen from "../../components/table/main";
import { Select, Tag, Icon } from "antd";
import { inject, observer } from "mobx-react";
import Moment from "react-moment";
const { Option } = Select;

const EditableContext = React.createContext();

let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  handleChange(dataIndex, value) {
    FormStore.modelObject[dataIndex] = value;
  }

  showPup(popTtype, bollean) {
    MainStore.actionButtonShow = bollean;
    Dashboard.showPup(popTtype);
  }

  showPup(popTtype, bollean) {
    MainStore.actionButtonShow = bollean;
    Dashboard.showPup(popTtype);
  }

  showMediaPop(Hash) {
    Dashboard.media(Hash).then((results) => {
      let image = results.length > 0 ? results[0].Url[0] : "";
      let images = results.length > 0 ? results[0].UrlMedia : [];
      Dashboard.MediaMainImage = image;
      Dashboard.MediaImages = images;
    });

    Dashboard.MediaPopHash = Hash;
    Dashboard.showMediaPop();
  }

  render() {
    FormStore.modelObject["OpenForMarketing"] = "כן";
    return (
      <div>
        <TableScreen
          model={"progects"}
          columns={[
            {
              title: "",
              dataIndex: "_id",
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "שם הפרוייקט",
              dataIndex: "ProjectName",
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
              search: true,
            },
            {
              title: "עיר",
              dataIndex: "City",
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "רחוב",
              dataIndex: "Street",
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "מס' רחוב",
              dataIndex: "StreetNumber",
              adding: true,
              required: true,
              type: "number",
              validation: [""],
            },

            {
              title: "תיאור",
              dataIndex: "Description",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "יזם",
              dataIndex: "Entrepreneur",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
              search: true,
            },

            {
              title: "אימייל",
              dataIndex: "Email",
              editable: true,
              adding: true,
              required: true,
              type: "email",
              validation: ["Email"],
              search: true,
            },
            {
              title: "טלפון",
              dataIndex: "PhoneNumber",
              editable: true,
              adding: true,
              required: true,
              type: "phone",
              validation: ["Phone"],
            },
            {
              title: "פתוח לשיווק",
              dataIndex: "OpenForMarketing",
              editable: true,
              adding: true,
              required: true,
              addRender: (
                <Select
                  onChange={(e) => this.handleChange("OpenForMarketing", e)}
                  placeholder="פתוח לשיווק?"
                  className="full marginTop10 textRight"
                >
                  <Option value="כן">כן</Option>
                  <Option value="לא">לא</Option>
                </Select>
              ),
              render: (OpenForMarketing) => (
                <Tag
                  color="blue"
                  color={OpenForMarketing === "כן" ? "green" : "blue"}
                >
                  {OpenForMarketing === "כן" ? "כן" : "לא"}
                </Tag>
              ),

              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "תמונות",
              dataIndex: "Hash",
              adding: false,
              render: (Hash) => (
                <div onClick={(e) => this.showMediaPop(Hash)}>
                  <Icon type="file-image" className="full marginCenter f1" />
                </div>
              ),
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "שאלות ותשובות",
              dataIndex: "Qas",
              adding: false,
              render: (Qas) => (
                <div onClick={(e) => this.showPup("qa", false)}>
                  <Icon
                    onClick={(e) =>
                      Qas.length > 0 && Dashboard.qas.length === 0
                        ? (Dashboard.qas = Qas[0].Qa)
                        : null
                    }
                    type="edit"
                    className="full marginCenter f1"
                  />
                </div>
              ),
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "אימייל לשאלות",
              dataIndex: "QaEmail",
              editable: true,
              adding: true,
              required: true,
              type: "email",
              validation: ["Email"],
              search: true,
            },

            {
              title: "פרוייקט נוצר",
              dataIndex: "timestamp",
              render: (timestamp) => (
                <Moment format="DD/MM/YYYY">{timestamp}</Moment>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "עריכה",
              dataIndex: "edit",
              render: (timestamp) => (
                <div onClick={(e) => (MainStore.showDrawerModal = true)}>
                  עריכה
                </div>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(Projects);
