import React from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Icon, Menu, Modal, Tabs } from "antd";
import { Header, Avatar, Container, Grid, Card } from "tabler-react";

import DashboardContent from "./DashboardContent";
import Projects from "./Projects";
import Assets from "./assets";
import PaymentRequirements from "./paymentRequirements";
import Deposits from "./Deposits";
import PaymentsReceived from "./paymentsReceived";
import Settings from "./Settings";
import Users from "./Users";

import "./main.css";

const { TabPane } = Tabs;

class AdminNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let t_check = localStorage.getItem("t_check");

    if (Number(t_check) !== 4) {
      window.location.href = "/";
    }
  };

  handleClick = (id) => {
    this.setState({ activeLink: id });
  };

  render() {
    const { activeLink } = this.state;
    return (
      <div className="full-height-fotter dashboard admin">
        <div className="right-menu">
          <Link
            onClick={() => this.handleClick("admin")}
            className={activeLink === "admin" ? " active_menu" : ""}
            to="/admin/main"
          >
            דאשבורד
          </Link>
          <Link
            onClick={() => this.handleClick("projects")}
            className={activeLink === "projects" ? " active_menu" : ""}
            to="/admin/projects"
          >
            פרוייקטים
          </Link>
          <Link
            onClick={() => this.handleClick("assets")}
            className={activeLink === "assets" ? " active_menu" : ""}
            to="/admin/assets"
          >
            נכסים
          </Link>
          <Link
            onClick={() => this.handleClick("payment-requirements")}
            className={
              activeLink === "payment-requirements" ? " active_menu" : ""
            }
            to="/admin/payment-requirements"
          >
            בקשת הפקדות
          </Link>
          <Link
            onClick={() => this.handleClick("deposits")}
            className={activeLink === "deposits" ? " active_menu" : ""}
            to="/admin/deposits"
          >
            הפקדות
          </Link>
          <Link
            onClick={() => this.handleClick("payments-received")}
            className={activeLink === "payments-received" ? " active_menu" : ""}
            to="/admin/payments-received"
          >
            תשלומים
          </Link>
          <Link
            onClick={() => this.handleClick("users")}
            className={activeLink === "users" ? "active_menu" : ""}
            to="/admin/users"
          >
            משתמשים
          </Link>
        </div>
        <div className="main-content">
          <Route exact path="/admin/main" component={DashboardContent} />
          <Route exact path="/admin/projects" component={Projects} />
          <Route exact path="/admin/assets" component={Assets} />
          <Route
            exact
            path="/admin/payment-requirements"
            component={PaymentRequirements}
          />
          <Route exact path="/admin/deposits" component={Deposits} />

          <Route
            exact
            path="/admin/payments-received"
            component={PaymentsReceived}
          />
          <Route exact path="/admin/users" component={Users} />
        </div>
      </div>
    );
  }
}

export default withRouter(AdminNav);
