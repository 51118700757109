import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Modal, Button } from "antd";
import { inject, observer } from "mobx-react";
import { IoLogoWhatsapp, IoMdMail, IoMdCall } from "react-icons/io";

/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class Contact extends Component {
  constructor(props) {
    super(props);
    MainStore = this.props.mainStore;
  }

  handleCancel = (e) => {
    MainStore.showContactModal = false;
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.newEvent({
      UserHash: user.Hash,
      UserName: user.FirstName + " " + user.LastName,
      Type: "יצירת קשר עם יזם",
      Message: `יצירת קשר עם יזם | ${MainStore.contactEmail} `,
      ProgetcName: MainStore.ProgetcName,
      AssetName: MainStore.AssetName,
    }).then((results) => {
    });
  }

  /* EVENTS */
  render() {
    return (
      <Modal
        className="menu-modal"
        visible={MainStore.showContactModal}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div className="b-white padding20">
          <div className="f3 bold  textCenter marginHor20">
            יצירת קשר עם היזם
          </div>
          <Row gutter={16}>
            <Col span={4}>
              <Button
                className="full br-green b-green"
                type="primary"
                size={"large"}
                href={`https://api.whatsapp.com/send?phone=${MainStore.contactPhone}&text=שלום+אני+יוצר+איתך+קשר+דרך+האפליקצייה+של+עמינח+שטיינמץ`}
              >
                <IoLogoWhatsapp />
              </Button>
            </Col>

            <Col span={20}>
              <Button
                className="full b-blue br-blue white"
                type="primary"
                size={"large"}
                href={`callto:${MainStore.contactPhone}`}
              >
                <IoMdCall className="marginLeft20" /> {MainStore.contactPhone}
              </Button>
            </Col>
          </Row>
          <Row className="marginTop10">
            <Col span={24}>
              <Button
                className="full br-blue blue "
                size={"large"}
                href={`mailto:${MainStore.contactEmail}`}
              >
                <IoMdMail className="marginLeft20" /> {MainStore.contactEmail}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
export default withRouter(Contact);
