import React, { Component } from "react";
import { Route, NavLink } from "react-router-dom";
import { Site, Button, Nav } from "tabler-react";

// style
import "./Nav.css";
// pages
import Feed from "../pages/user/feed/main";
import FindAsset from "../pages/user/findAsset/main";
import MyAssets from "../pages/user/myAssets/main";
import MyProjects from "../pages/user/myProjects/main";
import PaymentRequirements from "../pages/user/paymentRequirements/main";
import ProgectWrapper from "../pages/user/progect/progectWrapper/main";
// import QA from "../pages/user/qa/main";
// import Report from "../pages/user/report/main";
import Settings from "../pages/user/settings/main";

class UserNAV extends Component {
  state = {
    page: "login",
    linkURL: "",
    loading: false,
  };

  componentWillMount = () => {
    this.setState({
      linkURL: window.location.pathname.split("/").pop(),
    });
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  handleSwitchPage = (page) => {
    this.setState({ page: page });
  };

  render() {
    return (
      <div className="main home_page">
        <></>
        <div className="">
          <Route exact path="/" component={Feed} />
          <Route exact path="/find-asset" component={FindAsset} />
          <Route exact path="/my-assets" component={MyAssets} />
          {/* <Route exact path="/qa" component={QA} /> */}
          <Route
            exact
            path="/payment-requirements"
            component={PaymentRequirements}
          />
          {/* <Route exact path="/report" component={Report} /> */}
          <Route exact path="/settings" component={Settings} />

          {/* progect */}
          <Route exact path="/progect/:id" component={ProgectWrapper} />
        </div>
      </div>
    );
  }
}

export default UserNAV;
