import { Select } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import TableScreen from "../../components/table/main";

const { Option } = Select;

let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class Assets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progects: [],
      pageNumber: 1,
    };
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    MainStore.getAllByModelAndByUser("progects", {}).then((results) => {
      this.setState({ progects: results });
    });
  }

  paginataeAssets(e) {
    e.persist();

    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      MainStore.getAllByModelAndByUser(
        "progects",
        {},
        this.state.pageNumber
      ).then((results) => {
        if (results.length > 1) {
          this.setState({
            progects: [...this.state.progects, ...results],
            pageNumber: this.state.pageNumber + 1,
          });
        }
      });
    }
  }

  handleChangeMulti(dataIndex, value) {
    value.map(function (item, i) {
      Object.keys(item).forEach(function (key) {
        FormStore.modelObject[key] = item[key];
      });
    });
  }

  render() {
    let { progects } = this.state;
    return (
      <div className={" full-height-fotter"}>
        <TableScreen
          model={"assets"}
          columns={[
            {
              title: "",
              dataIndex: "_id",
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "פרויקט",
              dataIndex: "ProgectHash",
              display: false,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
              addRender: (
                <Select
                  onChange={(e) => this.handleChangeMulti("ProgectHash", e)}
                  placeholder="פרויקט"
                  className="full marginTop10 textRight"
                  onPopupScroll={(e) => this.paginataeAssets(e)}
                >
                  {progects.map((item, i) => (
                    <Option
                      value={[
                        (i: { ProgectId: item.Hash }),
                        { ProgectHash: item.Hash },
                        { ProjectName: item.ProjectName },
                        { City: item.City },
                        { Street: item.Street },
                        { StreetNumber: item.StreetNumber },
                      ]}
                      key={i}
                    >
                      <span className="bold">{item.ProjectName}: </span>
                      {`${item.City} ${item.Street} ${item.StreetNumber}`}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              title: "שם הפרויקט",
              dataIndex: "ProjectName",
              type: "text",
              validation: ["TextLength"],
              search: true,
            },
            {
              title: "עיר",
              dataIndex: "City",
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "רחוב",
              dataIndex: "Street",
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "מס' רחוב",
              dataIndex: "StreetNumber",
              type: "number",
              validation: [""],
            },
            {
              title: "קומה",
              dataIndex: "ApartmentFloor",
              adding: true,
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "מס' דירה",
              dataIndex: "ApartmentNumber",
              adding: true,
              required: true,
              search: true,
              type: "string",
              validation: [""],
            },
            {
              title: "מחיר",
              dataIndex: "AssetCost",
              editable: true,
              adding: true,
              required: true,
              type: "number",
              render: (AssetCost) => Dashboard.numberWithCommas(AssetCost),
              validation: [""],
            },

            {
              title: "נכס נוצר",
              dataIndex: "timestamp",
              render: (timestamp) => (
                <Moment format="DD/MM/YYYY">{timestamp}</Moment>
              ),
              adding: false,
              type: "text",
              validation: ["Date"],
            },
            {
              title: "עריכה",
              dataIndex: "edit",
              render: (timestamp) => (
                <div onClick={(e) => (MainStore.showDrawerModal = true)}>
                  עריכה
                </div>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(Assets);
