import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import UserNAV from "./app/nav/UserNAV";
import AdminNav from "./app/pages/admin/dashboard/Dahboard";
import Login from "./app/pages/auth/Login";

import "./app/handler/Global";

// stores
import { observer, Provider } from "mobx-react";
import AuthStore from "./app/stores/auth-store";
import MainStore from "./app/stores/main-store";
import Dashboard from "./app/stores/dashboard";
import FormStore from "./app/stores//form-store";

const mainStore = new MainStore();
const authStore = new AuthStore();
const dashboard = new Dashboard();
const formStore = new FormStore();

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
    };
  }

  render() {
    return (
      <Provider
        authStore={authStore}
        mainStore={mainStore}
        dashboard={dashboard}
        formStore={formStore}
      >
        <BrowserRouter>
          <div className="App">
            {authStore.CheckLogin() ? (
              <Route path={"/"} component={UserNAV} />
            ) : (
              <Route exact path={"/"} component={Login} />
            )}
            <Route path={"/admin"} component={AdminNav} />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
