import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Collapse, Icon } from "antd";

/* STYLES */
import "./main.css";

/* VARIABLES*/
const { Panel } = Collapse;

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden"
};

/* STORES */
class CollapseList extends Component {
  constructor(props) {
    super(props);
  }

  /* EVENTS */
  render() {
    const { qaItems } = this.props;

    return (
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <Icon type="caret-right" rotate={isActive ? 90 : 0} />
        )}
      >
        {qaItems.length > 0 ? (
          qaItems[0].Qa.map((item, i) => (
            <Panel header={item.q} key={i} style={customPanelStyle}>
              <p>{item.a}</p>
            </Panel>
          ))
        ) : (
          <div className="textCenter bold black f1">
            אין שאלות מעודכנות לפרוייקט
          </div>
        )}
      </Collapse>
    );
  }
}
export default withRouter(CollapseList);
