import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";
// COMPONENTS
import CollapseList from "../../../components/collapseList/main";

/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class QA extends Component {
  constructor(props) {
    super(props);
    MainStore = this.props.mainStore;
  }

  /* EVENTS */
  render() {
    let ProjectQas = MainStore.selectedAssetData.assets.ProjectQas;
    let ProjectEntrepreneurQaEmail =
      MainStore.selectedAssetData.assets.ProjectEntrepreneurQaEmail;

    return (
      <div className="QA conatiner-bottom">
        {ProjectEntrepreneurQaEmail ? (
          <div className="textCenter marginHor20">
            לשאלות ופניות ניתן לשלוח מייל ל{ProjectEntrepreneurQaEmail}
          </div>
        ) : null}

        <CollapseList qaItems={ProjectQas} />
      </div>
    );
  }
}
export default withRouter(QA);
