import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Collapse, Input, Icon, Button } from "antd";
import { inject } from "mobx-react";
import { action, observable } from "mobx";

import CollapseList from "../../components/collapseList/main";
/* STYLES */
import "./main.css";

/* VARIABLES*/
const { Panel } = Collapse;
const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

let id = 0;

/* STORES */
let MainStore;
@inject("mainStore", "dashboard")
class QaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: "",
      a: "",
      addQanAValidation: null,
    };
    MainStore = this.props.mainStore;
    // Dashboard = this.props.dashboard;
  }

  @action remove(i, item) {
    this.setState({
      q: "",
      a: "",
      addQanAValidation: ".",
    });
    let qas = this.props.dashboard.qas.filter(Boolean);

    let not = qas.filter((qa, index) => {
      return index !== i;
    });

    this.props.dashboard.qas = not;
    this.save();
  }

  @action add() {
    if (this.state.q.length > 2 && this.state.a.length > 2) {
      let q = this.state.q;
      let a = this.state.a;
      this.props.dashboard.qas.push({ q: q, a: a });

      this.setState({
        q: "",
        a: "",
        addQanAValidation: "",
      });

      this.save();
    } else {
      this.setState({
        addQanAValidation: "שאלה ותשובה חייבים לפחות 3 תווים כל אחד",
      });
    }
  }

  save() {
    fetch(`${global.apiUrl}/qa/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        qaItems: this.props.dashboard.qas,
        Hash: this.props.Hash,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {

      })
      .catch((error) => {
      });
  }

  /* EVENTS */
  render() {
    const { url, model, Hash } = this.props;
    const qas = this.props.dashboard.qas.filter(Boolean);
    const count = qas.length;

    return (
      <div>
        <Row className="marginHor20">
          <Col onClick={(e) => this.add()} span={2}>
            <Icon type="plus" className="full marginCenter f1" />
          </Col>

          <Col span={11}>
            <Input
              style={{ width: "90%" }}
              placeholder={`תשובה`}
              value={this.state.a}
              onChange={(e) => {
                this.setState({ a: e.target.value });
              }}
              className="marginVart inputFrom"
            />
          </Col>

          <Col span={11}>
            <Input
              style={{ width: "90%" }}
              placeholder={`שאלה`}
              value={this.state.q}
              onChange={(e) => {
                this.setState({ q: e.target.value });
              }}
              className="marginVart inputFrom"
            />
          </Col>

          <div className="error">{this.state.addQanAValidation}</div>
        </Row>

        {this.props.dashboard.qas.map((item, i) => (
          <div>
            <Col style={{ marginTop: "13px" }} span={2}>
              <Icon
                onClick={(e) => this.remove(i, item)}
                type="delete"
                className="full marginCenter f3 red cursor"
              />
            </Col>

            <Col span={22}>
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel header={item.q} key={i} style={customPanelStyle}>
                  <p>{item.a}</p>
                </Panel>
              </Collapse>
            </Col>
          </div>
        ))}

        {/* <Button onClick={(e) => this.save()} type="primary" htmlType="submit">
          שמור
        </Button> */}
      </div>
    );
  }
}
export default withRouter(QaEdit);
