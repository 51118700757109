import { DatePicker, Icon, Select, Tag } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import TableScreen from "../../components/table/main";

const { Option } = Select;
const EditableContext = React.createContext();

/* STORES */

let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class PaymentRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: "",
      assets: [],
      pageNumber: 1,
    };
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    MainStore.getAllByModelAndByUser("assets", {}).then((results) => {
      this.setState({ assets: results });
    });
  }

  paginataeAssets(e) {
    e.persist();

    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      MainStore.getAllByModelAndByUser(
        "assets",
        {},
        this.state.pageNumber
      ).then((results) => {
        if (results.length > 1) {
          this.setState({
            assets: [...this.state.assets, ...results],
            pageNumber: this.state.pageNumber + 1,
          });
        }
      });
    }
  }

  handleChange(dataIndex, value) {
    FormStore.modelObject[dataIndex] = value;
  }

  handleChangeMulti(value) {
    value.map(function (item, i) {
      Object.keys(item).forEach(function (key) {
        FormStore.modelObject[key] = item[key];
      });
    });
  }

  showPup(popTtype, bollean) {
    MainStore.actionButtonShow = bollean;
    Dashboard.showPup(popTtype);
  }

  render() {
    // FormStore.modelObject["CashMust"] = "הון עצמי";
    // FormStore.modelObject["InBudget"] = "כן";
    let { assets } = this.state;
    return (
      <div className={"full-height-fotter"}>
        <TableScreen
          model={"paymentRequirements"}
          columns={[
            {
              title: "",
              dataIndex: "_id",
              required: true,
              type: "number",
              validation: [""],
            },
            {
              title: "ID בקשה",
              dataIndex: "paymentID",

              adding: true,
              required: true,
              type: "number",
              validation: [""],
            },

            {
              title: "נכס",
              dataIndex: "ProgectHash",
              display: false,
              editable: false,
              adding: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
              addRender: (
                <Select
                  onChange={(e) => this.handleChangeMulti(e)}
                  placeholder="נכס"
                  className="full marginTop10 textRight"
                  onPopupScroll={(e) => this.paginataeAssets(e)}
                >
                  {assets.map((item, i) => (
                    <Option
                      value={[
                        (i: { ProgectId: item.Hash }),
                        { AssetHash: item.Hash },
                        { ProjectName: item.ProjectName },
                        { City: item.City },
                        { Street: item.Street },
                        { StreetNumber: item.StreetNumber },
                        { ApartmentNumber: item.ApartmentNumber },
                      ]}
                    >
                      <span className="bold">{item.ProjectName}: </span>
                      {`${item.City} ${item.Street} ${item.StreetNumber} דירה ${item.ApartmentNumber}`}
                    </Option>
                  ))}
                </Select>
              ),
            },

            {
              title: "שם הפרויקט",
              dataIndex: "ProjectName",
              required: true,
              search: true,
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "עיר",
              dataIndex: "City",
              required: true,
              search: true,
              type: "text",
              validation: ["TextLength"],
            },
            {
              title: "רחוב",
              dataIndex: "Street",
              search: true,
              required: true,
              type: "text",
              validation: ["TextLength"],
            },

            {
              title: "מס' רחוב",
              search: true,
              dataIndex: "StreetNumber",
              required: true,
              type: "number",
              validation: [""],
            },

            {
              title: "מס' דירה",
              dataIndex: "ApartmentNumber",
              required: true,
              search: true,
              type: "number",
              validation: [""],
            },

            {
              title: "צורת תשלום",
              dataIndex: "CashMust",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: [""],
              render: (CashMust) => {
                if (CashMust === "שניהם") {
                  return "שניהם";
                } else if (CashMust === "הון עצמי") {
                  return "הון עצמי";
                } else if (CashMust === "אשראי") {
                  return "אשראי";
                }
              },

              addRender: (
                <Select
                  onChange={(e) => this.handleChange("CashMust", e)}
                  placeholder="צורת התשלום"
                  className="full marginTop10 textRight"
                >
                  <Option value="שניהם">הון עצמי ואשראי</Option>
                  <Option value="הון עצמי">הון עצמי</Option>
                  <Option value="אשראי">אשראי</Option>
                </Select>
              ),
            },

            {
              title: "סכום",
              dataIndex: "PaymentAmount",
              editable: true,
              adding: true,
              required: true,
              type: "PaymentAmount",
              render: (number) => Dashboard.numberWithCommas(number),
              validation: [""],
            },
            {
              title: "להפקדה עד",
              dataIndex: "PaymentDate",
              editable: true,
              adding: true,
              required: true,
              addRender: (
                <DatePicker
                  className="full marginTop10 textRight"
                  onChange={(e, d) =>
                    FormStore.addAdminDateOnChangeDate("PaymentDate", e, d)
                  }
                  format={"DD/MM/YYYY"}
                  placeholder={"בחר תאריך"}
                />
              ),
              type: "text",
              validation: ["Date"],
            },
            {
              title: "המכתב שנשלח",
              dataIndex: "Media",
              adding: false,
              image: true,
              render: (Media) => (
                <div onClick={(e) => this.showPup("pdf", false)}>
                  {Media !== undefined && Media.length > 0 ? (
                    <Icon type="file-pdf" className="full marginCenter f1" />
                  ) : (
                    <Icon type="edit" className="full marginCenter f1" />
                  )}
                </div>
              ),
              type: "text",
              validation: [""],
            },

            {
              title: "בתקציב",
              dataIndex: "InBudget",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: [""],
              render: (InBudget) => (
                <Tag color="blue" color={InBudget === "כן" ? "green" : "blue"}>
                  {InBudget}
                </Tag>
              ),
              addRender: (
                <Select
                  onChange={(e) => this.handleChange("InBudget", e)}
                  placeholder="בתקציב?"
                  className="full marginTop10 textRight"
                >
                  <Option value="כן">כן</Option>
                  <Option value="לא">לא</Option>
                </Select>
              ),
            },
            {
              title: `מע"מ`,
              dataIndex: "IsVat",
              editable: true,
              adding: true,
              required: true,
              type: "text",
              validation: [""],
              render: (IsVat) => (
                <Tag color="blue" color={IsVat === "כן" ? "green" : "blue"}>
                  {IsVat}
                </Tag>
              ),
              addRender: (
                <Select
                  onChange={(e) => this.handleChange("IsVat", e)}
                  placeholder={`מע"מ?`}
                  className="full marginTop10 textRight"
                >
                  <Option value="כן">כן</Option>
                  <Option value="לא">לא</Option>
                </Select>
              ),
            },

            {
              title: `סטאטוס`,
              dataIndex: "Open",
              editable: false,
              required: false,
              type: "text",
              search: true,
              validation: [""],
              render: (Open, record) => {
                // let deposits = record.Deposits ? record.Deposits : null;
                // let depositsAmounts = 0;
                // if (deposits) {
                //   const depositsSumPaymentAmount = (
                //     accumulator,
                //     { PaymentAmount }
                //   ) => accumulator + Number(PaymentAmount);
                //   depositsAmounts = deposits.reduce(
                //     depositsSumPaymentAmount,
                //     0
                //   );
                // }

      
                if (Open === "סגור") {
                  return (
                    <Tag color="blue" color={"green"}>
                      סגור
                    </Tag>
                  );
                } else {
                  return (
                    <Tag color="blue" color={"blue"}>
                      פתוח
                    </Tag>
                  );
                }
              },
            },

            {
              title: "עריכה",
              dataIndex: "edit",
              render: (timestamp) => (
                <div onClick={(e) => (MainStore.showDrawerModal = true)}>
                  עריכה
                </div>
              ),
              adding: false,
              type: "text",
              validation: ["TextLength"],
            },
          ]}
        />
        )
      </div>
    );
  }
}

export default withRouter(PaymentRequirements);
