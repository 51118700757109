import { action, observable } from "mobx";
export default class MainStore {
  @observable allAssets = [];
  // models
  @observable showContactModal = false;
  @observable actionButtonShow = true;

  @observable contactPhone = "";
  @observable contactEmail = "";
  @observable selectedAsset = "";
  @observable selectedAssetData = [];

  @observable rowHash = "";
  @observable assetsList = [];
  @observable defaultValue = "";

  // contact event
  @observable ProgetcName = "";
  @observable AssetName = "";

  // drawer
  @observable showDrawerModal = false;

  @observable user = JSON.parse(localStorage.getItem("user"));

  // we need to add userId
  @action getAllByModelAndByUser(
    model,
    filters = {},
    pageNumber = 0,
    perPage = 10,
    isAdmin = false
  ) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/${model}/all`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filters: filters,
          page: pageNumber,
          perPage,
          isAdmin,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          
          resolve(responseJson.items);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }


  // USER PAYMENTS
  // get assets [left join progect and media] by user
  @action getPaymentsByUser(model, filters = {}) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/${model}/all/user`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filters: filters,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {

          resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  // show enterpenoor contact info from any page
  @action oneProgect(hash) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/progects/one-progect`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hash: hash,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    });
  }

  @action showContactModalFunc(hash) {
    fetch(`${global.apiUrl}/progects/one-progect`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hash: hash,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.contactPhone = responseJson.PhoneNumber;
        this.contactEmail = responseJson.Email;
        this.showContactModal = true;
      });
  }

  // uniq assets
  @action assetsOne() {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/assets/one-progect`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filters: { Hash: this.selectedAsset },
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
   

          resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  // uniq assets
  @action newEvent(obj) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/events/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          obj: obj,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {

          resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }


  // MY ASSETS
  // get assets [left join progect and media] by user
  @action getAsstsByUser(model, filters = {}, isAdmin = false,) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/${model}/all/user`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filters: filters,
          isAdmin
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {


          resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  // USER ASSET LIST HANDLE
  @action getAseetsListByUser(isAdmin = false) {
    this.getAsstsByUser(
      "assets",
      {
        Hash: this.rowHash,
      },
      isAdmin
    ).then((results) => {
      this.assetsList = results;
    });
  }

  @action AddAssetToUser(AssetHash) {
    fetch(`${global.apiUrl}/users/AddAssetToUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Hash: this.rowHash,
        AssetArrayHash: AssetHash,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // this.assetsList = responseJson;
      })
      .catch((error) => { });
  }

  @action removeAssetFromUser(AssetHash) {
    fetch(`${global.apiUrl}/users/removeAssetFromUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Hash: this.rowHash,
        AssetHash: AssetHash,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.user.nModified) {
          this.assetsList = this.assetsList.filter(function (value, index) {
            return value.assets.AssetsHash !== AssetHash;
          });
        }
      })
      .catch((error) => { });
  }
}
