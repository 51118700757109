import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Row, Col, Badge } from "antd";

/* STYLES */
import "./main.css";

/* STORES */
let Dashboard;
@inject("dashboard")
@observer
class GridCard extends Component {
  constructor(props) {
    super(props);
    Dashboard = this.props.dashboard;
  }

  /* EVENTS */
  render() {
    let {
      type,
      color,
      col1,
      col2,
      col3,
      totalPaymentsSum,
      OutBudget,
      InBudget,
    } = this.props;

    let InBudgetPrecenteg = ((InBudget * 100) / totalPaymentsSum).toFixed(0);
    let OutBudgetPrecenteg = ((OutBudget * 100) / totalPaymentsSum).toFixed(0);
    return (
      <div>
        {type === "payment" ? (
          <div className="b-white padding20 marginTop20">
            <Row>
              <Col className="f2 textLeft bold" span={12}>
                <div className="f2 bold fLeft marginRight20 ">{col1}</div>
                <div className="f2">{col2} &#8362;</div>
              </Col>

              <Col className="f2 bold textRight marginBot20" span={12}>
                <Badge
                  className="f2 fright marginLeft20 "
                  dot={true}
                  style={{ lineHeight: "2.2" }}
                  color={color}
                ></Badge>
                <div className="f2"> {col3}</div>
              </Col>

              {InBudget > 0 ? (
                <Col className="show bold textRight" span={24}>
                  <Col className="f4 textLeft bold   " span={12}>
                    <div
                      className="f4 bold fLeft marginRight40"
                      style={{ color: "#558cb1", margingLeft: "35px" }}
                    >
                      {InBudgetPrecenteg + "%"}
                    </div>
                    <div className="f4">
                      {Dashboard.numberWithCommas(InBudget)} &#8362;
                    </div>
                  </Col>

                  <Col className="f4 bold textRight" span={12}>
                    <Badge
                      className="f4 fright"
                      dot={true}
                      color={color}
                    ></Badge>
                    <div className="f4"> תקציב</div>
                  </Col>
                </Col>
              ) : null}

              {OutBudget > 0 ? (
                <Col className="f4 bold textRight" span={24}>
                  <Col className="f4 textLeft bold   " span={12}>
                    <div
                      className="f4 bold fLeft marginRight40 "
                      style={{ color: "#558cb1", margingLeft: "35px" }}
                    >
                      {OutBudgetPrecenteg + "%"}
                    </div>
                    <div className="f4">
                      {Dashboard.numberWithCommas(OutBudget)} &#8362;
                    </div>
                  </Col>

                  <Col className="f4 bold textRight" span={12}>
                    <Badge
                      className="f4 fright  "
                      dot={true}
                      color={color}
                    ></Badge>
                    <div className="f4"> חוץ תקציבי</div>
                  </Col>
                </Col>
              ) : null}
            </Row>
          </div>
        ) : (
          <div className="b-white padding20 marginTop20">
            <Row>
              <Col className={"f2 textLeft"} span={1}>
                <Badge dot={true} color={col1}></Badge>
              </Col>

              <Col className="f2 textLeft bold blue " span={10}>
                {Dashboard.numberWithCommas(col2)} &#8362;
              </Col>

              <Col
                className="f2 bold textRight flex column"
                offset={5}
                span={8}
              >
                {col3} <span className="f16"> {this.props.cashOrCredit}</span>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(GridCard);
