import React from "react";
import { withRouter, Route, NavLink } from "react-router-dom";
import "moment-timezone";

import { Container } from "tabler-react";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Container>Settings</Container>;
  }
}

export default withRouter(Settings);
