import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button, Icon, DatePicker } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";

import PaymentBadge from "../../../components/paymentBadge/main";
import GridCard from "../../../components/gridCard/main";

/* STYLES */
import "./main.css";

const { parse } = require("json2csv");

const { RangePicker } = DatePicker;
let Cash = 0;
let Credit = 0;

/* STORES */
let Dashboard;
let MainStore;
@inject("dashboard", "mainStore")
@observer
class MyDeposits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Cash: 0,
      Credit: 0,
      Deposits: [],
      cashTotal: 0,
      creditTotal: 0,
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.newEvent({
      UserHash: user.Hash,
      UserName: user.FirstName + " " + user.LastName,
      Type: 'פתיחת דו"ח',
      Message: 'פתיחת דו"ח',
      ProgetcName: MainStore.selectedAssetData.assets.Street,
      AssetName: `${MainStore.selectedAssetData.assets.StreetNumber} ${MainStore.selectedAssetData.assets.ProjectName}`,
    }).then((results) => {
    });

    let AssetsHash = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    debugger;
    MainStore.selectedAsset = AssetsHash;
    MainStore.assetsOne().then((results) => {
      let deposits = results[0].payments.deposits;

      // check OpenDeposit
      if (deposits.length > 0) {
        for (let x = 0; x < deposits.length; x++) {
          let item = deposits[x];
          if (item.CashMust === "הון עצמי") {
            this.setState({
              cashTotal: this.state.cashTotal + deposits[x].PaymentAmount,
            });
          } else {
            this.setState({
              creditTotal: this.state.creditTotal + deposits[x].PaymentAmount,
            });
          }
        }
      }

      this.setState({
        Deposits: deposits,
      });

      MainStore.selectedAssetData = results[0];
    });
  }

  download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  downloadCsv(Deposits) {
    let fileds = [
      "ProjectName",
      "City",
      "Street",
      "StreetNumber",
      "ApartmentNumber",
      "CashMust",
      "PaymentAmount",
      "PaymentDate",
      "InBudget",
      "IsVat",
    ];

    let items = Deposits.map((item) => {
      Object.keys(item).forEach((key) => {
        if (!fileds.includes(key)) {
          delete item[key];
        }
      });
      return item;
    });

    const csv = parse(items);
    this.download(`הפקדות.csv`, csv);
  }

  /* EVENTS */
  render() {
    let { Deposits, cashTotal, creditTotal } = this.state;
    let today = moment(new Date()).format("DD-MM-YYYY");
    return (
      <div className="MyDeposits conatiner-bottom dashboard">
        <div>
          <img
            src={
              "https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            }
            className="imageFull"
            style={{ maxHeight: "80px" }}
          ></img>
        </div>

        <div className="padding20">
          <Row className={"f1 bold textCenter"}>
            {MainStore.selectedAssetData.assets.Street}{" "}
            {MainStore.selectedAssetData.assets.StreetNumber} ,{" "}
            {MainStore.selectedAssetData.assets.ProjectName}
          </Row>

          {/* <Row className="marginTop20">
          

            <RangePicker
              className="select-date-eport"
              placeholder={["תאריך התחלה", "תאריך סוף"]}
            />
          </Row> */}
          {/* <Row className="gray textCenter">מעודכן ליום {today}</Row> */}
          <Row gutter={24} className="paddingVer40">
            <Col className="padding20" span={12}>
              <PaymentBadge
                color="#812770"
                amount="הון עצמי"
                text={`${
                  cashTotal ? Dashboard.numberWithCommas(cashTotal) : 0
                } סה"כ`}
              />
            </Col>

            <Col className="padding20" span={12}>
              <PaymentBadge
                color="#005290"
                amount="אשראי"
                text={` ${
                  creditTotal ? Dashboard.numberWithCommas(creditTotal) : 0
                } סה"כ`}
              />
            </Col>
          </Row>

          <div className="buttun-download">
            <Button
              type="link"
              className="circle"
              onClick={() => this.downloadCsv(Deposits)}
            >
              הורד
              <Icon className="f4 cursor marginRight5" type="file-excel" />
            </Button>
          </div>
          <Row gutter={24}>
            {Deposits.map((item, i) => (
              <GridCard
                col1={item.CashMust === "אשראי" ? "#005290" : "#812770"}
                col2={Dashboard.numberWithCommas(item.PaymentAmount)}
                col3={item.PaymentDate}
                cashOrCredit={item.CashMust}
              />
            ))}
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(MyDeposits);
