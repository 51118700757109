import { action, observable } from "mobx";
import MainStore from "./main-store";

export default class AuthStore {
  @observable userLogin = false;
  @observable userAdmin = false;
  @observable messageSent = false;
  @observable formValid = true;

  @observable PhoneNumber = "";
  @observable errorPhone = "";

  @action CheckLogin() {
    if (localStorage.getItem("login")) {
      return true;
    } else {
      return false;
    }
  }

  @action LoginWithPhone() {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/users/login`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PhoneNumber: this.PhoneNumber,
        }),
      })
       
        .then((responseJson) => {
         
          this.messageSent = true;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action resendPassword(phone) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/users/login/resend`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PhoneNumber: this.PhoneNumber,
        }),
      })
       
        .then((responseJson) => {
          this.messageSent = true;
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  @action validateOtp(code) {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/users/validate`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PhoneNumber: this.PhoneNumber,
          Token:code
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  @action SendMessage() {
    this.messageSent = true;
  }

  @action SubmitLogin() {
    this.userLogin = true;
  }

  @action Logout() {}

  // VALIDATION

  @action ValidationPhoneNumber(val) {
    if (val.length <= 10) {
      this.errorPhone = "";
      this.PhoneNumber = val;
      this.formValid = true;
    } else {
      this.errorPhone = "נא להכניס מספר תקין";
      this.formValid = false;
    }
    return;
  }
}
