global.userId = "";
global.mainImage = "https://pbs.twimg.com/profile_images/1421621283/cpa.JPG";
global.placeholder = "../../../assets/img/placeholder.png";
global.accessToken = null;
global.promocode = "";
global.welcome = null;
global.fullName = null;
global.VCNprice = 0.5;
global.custom = false;
global.chefHeat = "https://i.dlpng.com/static/png/481464_preview.png";

// AXIOS CONFIG
global.axiosConfig = {
  withCredentials: true,
  crossdomain: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

global.apiUrl = process.env.REACT_APP_API;
