import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Badge, Button, Skeleton } from "antd";
import PieChart from "react-minimal-pie-chart";
import { inject, observer } from "mobx-react";

import CarouselWidget from "../../../components/carousel/main";
import PaymentBadge from "../../../components/paymentBadge/main";
import Contact from "../../../components/contact/main";

/* STYLES */
import "./main.css";

let InBudget = 0;
let OutBudget = 0;
let OpenDeposit = 0;
let RemainPayment = 0;

/* STORES */
let Dashboard;
let MainStore;
@inject("dashboard", "mainStore")
@observer
class ProgectMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "mail",
      showContactModal: false,
      data: [],
      InBudget: 0,
      OutBudget: 0,
      OpenDeposit: 0,
      RemainPayment: 0,
      InBudgetPrecent: 0,
      OutBudgetPrecent: 0,
      OpenDepositPrecent: 0,
      RemainPaymentPrecent: 0,
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    InBudget = 0;
    OutBudget = 0;
    OpenDeposit = 0;
    RemainPayment = 0;

    let AssetsHash = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    MainStore.selectedAsset = AssetsHash;
    MainStore.assetsOne().then((results) => {
      let paymentrequirements = results[0].payments.paymentrequirements;
      let paymentsreceiveds = results[0].payments.paymentsreceiveds;
      let deposits = results[0].payments.deposits;
      let assetCost = results[0].assets.AssetCost;
      assetCost = parseInt(assetCost);

      let paymentrequirementsTotal = 0;
      let depositsTotal = 0;
      let depositsTotal2 = 0;
      let openPaymentsRequst = 0;

      // check InBudget and OutBudget
      if (paymentsreceiveds !== undefined && paymentsreceiveds.length > 0) {
        for (let i = 0; i < paymentsreceiveds.length; i++) {
          let item = paymentsreceiveds[i];
          let paymentAmount = parseInt(item.PaymentAmount);
          if (item.InBudget === "כן") {
            InBudget = InBudget + paymentAmount;
          } else {
            OutBudget = OutBudget + paymentAmount;
          }
        }
      } else {
        let item = paymentsreceiveds;
        let paymentAmount;
        if (item !== undefined) {
          let paymentAmount =
            item.PaymentAmount !== undefined ? parseInt(item.PaymentAmount) : 0;
          if (item.InBudget === "כן") {
            InBudget = InBudget + paymentAmount;
          } else {
            OutBudget = OutBudget + paymentAmount;
          }
        } else {
          paymentAmount = 0;
        }
      }

      // check paymentrequirements
      if (paymentrequirements !== undefined && paymentrequirements.length > 0) {
        for (let y = 0; y < paymentrequirements.length; y++) {
          let item = paymentrequirements[y];
          let paymentAmount = parseInt(item.PaymentAmount);
          paymentrequirementsTotal = paymentrequirementsTotal + paymentAmount;
        }
      } else {
        let item = paymentrequirements;
        let paymentAmount;
        if (item !== undefined) {
          paymentAmount =
            item.PaymentAmount !== undefined ? parseInt(item.PaymentAmount) : 0;
        } else {
          paymentAmount = 0;
        }

        paymentrequirementsTotal = paymentrequirementsTotal + paymentAmount;
      }

      // check OpenDeposit
      if (deposits !== undefined && deposits.length > 0) {
        for (let x = 0; x < deposits.length; x++) {
          let item = deposits[x];

          let paymentAmount = parseInt(item.PaymentAmount);
          if (item.InBudget === "כן") {
            depositsTotal = depositsTotal + paymentAmount;
          }

          depositsTotal2 = depositsTotal2 + paymentAmount;
        }
      } else {
        let item = deposits;
        let paymentAmount;
        if (item !== undefined) {
          if (item.InBudget === "כן") {
            paymentAmount =
              item.PaymentAmount !== undefined
                ? parseInt(item.PaymentAmount)
                : 0;
          }
        } else {
          paymentAmount = 0;
        }

        depositsTotal = depositsTotal + paymentAmount;
        depositsTotal2 = depositsTotal2 + paymentAmount;
      }

      Array.prototype.sum = function (paymentId, prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          if (this[i]["paymentID"] === paymentId) {
            total += Number(this[i][prop]);
          }
        }
        return total;
      };

      // check open Payment Request
      let expens;
      if (paymentrequirements !== undefined && paymentrequirements.length > 0) {
        for (let z = 0; z < paymentrequirements.length; z++) {
          let paymentId = paymentrequirements[z].paymentID;
          let depositsById = deposits.sum(paymentId, "PaymentAmount");
          let remainPayments =
            paymentrequirements[z].PaymentAmount - depositsById;

          expens = InBudget + OutBudget;
          if (remainPayments >= 0) {
            openPaymentsRequst = depositsTotal2 - expens;
          }
        }
      }

      // this.setState({
      //   data: results[0],
      //   InBudget: InBudget,
      //   OutBudget: OutBudget,
      //   OpenDeposit: openPaymentsRequst,
      //   RemainPayment: assetCost - depositsTotal,
      //   InBudgetPrecent: (InBudget / assetCost) * 100,
      //   OutBudgetPrecent: (OutBudget / assetCost) * 100,
      //   OpenDepositPrecent: ((depositsTotal - expens) / assetCost) * 100,
      //   RemainPaymentPrecent: ((assetCost - depositsTotal) / assetCost) * 100,
      // });

      this.setState({
        data: results[0],
        InBudget: InBudget,
        OutBudget: OutBudget,
        OpenDeposit: openPaymentsRequst,
        RemainPayment: assetCost - depositsTotal2 + OutBudget,
        InBudgetPrecent: (InBudget / assetCost) * 100,
        OutBudgetPrecent: (OutBudget / assetCost) * 100,
        OpenDepositPrecent: ((depositsTotal - expens) / assetCost) * 100,
        RemainPaymentPrecent: ((assetCost - depositsTotal) / assetCost) * 100,
      });

      MainStore.selectedAssetData = results[0];
    });
  }

  /* EVENTS */
  showContactModal(hash) {
    MainStore.showContactModalFunc(hash);
  }

  nFormatter(num, digits) {
    num = parseFloat(num);

    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    // for negative value is work
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  render() {
    let { data } = this.state;

    if (data.assets !== undefined) {
      return (
        <div className="conatiner-bottom ProgectMain">
          {/* <div>
            {data.assets.ProjectImages !== undefined &&
            data.assets.ProjectImages.length > 1 ? (
              <CarouselWidget
                style={{ width: "100%", height: "100%" }}
                images={data.assets.ProjectImages}
              />
            ) : data.assets.ProjectMainImage !== undefined &&
              data.assets.ProjectMainImage.length > 0 ? (
              <img
                style={{ width: "100%", height: "100%" }}
                src={data.assets.ProjectMainImage}
              />
            ) : null}
          </div> */}
          <div className="padding20">
            <Row>
              <Col className={"f1 textLeft"} span={14}>
                {data.assets.Street} {data.assets.StreetNumber} ,{" "}
                {data.assets.ProjectName}
              </Col>

              <Col className="f2 textLeft bold textRight" span={10}>
                דירה {data.assets.ApartmentNumber} | קומה{" "}
                {data.assets.ApartmentFloor}
              </Col>
            </Row>
          </div>

          <div className="padding20">
            <div className="b-white padding20 box-static">
              <Row gutter={16}>
                <Col className="padding20" span={8}>
                  <PaymentBadge
                    color="#dbdbdb"
                    amount={
                      Dashboard.numberWithCommas(this.state.OpenDeposit)
                        ? Dashboard.numberWithCommas(this.state.OpenDeposit)
                        : 0
                    }
                    text={
                      this.state.OpenDeposit > 0 ? "יתרת זכות" : "יתרת חובה"
                    }
                  />

                  <PaymentBadge
                    color="#ed001c"
                    amount={
                      Dashboard.numberWithCommas(this.state.RemainPayment)
                        ? Dashboard.numberWithCommas(this.state.RemainPayment)
                        : 0
                    }
                    text="יתרה להפקדה"
                  />
                </Col>

                <Col className="padding20" span={8}>
                  <PaymentBadge
                    color="#9b00ff"
                    amount={
                      Dashboard.numberWithCommas(this.state.InBudget)
                        ? Dashboard.numberWithCommas(this.state.InBudget)
                        : 0
                    }
                    text="תקציבי"
                  />

                  <PaymentBadge
                    color="#005290"
                    amount={
                      Dashboard.numberWithCommas(this.state.OutBudget)
                        ? Dashboard.numberWithCommas(this.state.OutBudget)
                        : 0
                    }
                    text="חוץ תקציבי"
                  />
                </Col>

                <Col className="" span={8}>
                  <PieChart
                    style={{ height: "125px" }}
                    animate={false}
                    animationDuration={500}
                    animationEasing="ease-out"
                    cx={50}
                    cy={50}
                    data={[
                      {
                        color: "#9b00ff",
                        title: "One",
                        value: this.state.InBudgetPrecent,
                      },
                      {
                        color: "#005290",
                        title: "Two",
                        value: this.state.OutBudgetPrecent,
                      },
                      {
                        color: "#ed001c",
                        title: "Three",
                        value:
                          this.state.RemainPaymentPrecent > 0
                            ? this.state.RemainPaymentPrecent
                            : 0,
                      },
                      {
                        color: "#dbdbdb",
                        title: "Three",
                        value:
                          this.state.OpenDepositPrecent > 0
                            ? this.state.OpenDepositPrecent
                            : 0,
                      },
                    ]}
                    label={false}
                    labelPosition={50}
                    lengthAngle={360}
                    lineWidth={15}
                    onClick={undefined}
                    onMouseOut={undefined}
                    onMouseOver={undefined}
                    paddingAngle={0}
                    radius={40}
                    rounded={false}
                    startAngle={0}
                    viewBoxSize={[40, 40]}
                  >
                    <div className="textCircle">
                      <div>סה"כ</div>
                      <div className="blue bold">
                        {this.nFormatter(
                          this.state.InBudget + this.state.OutBudget,
                          1
                        )}
                      </div>
                      <div>
                        <Badge dot={this.state.show}>
                          <a href="#" className="head-example" />
                        </Badge>
                      </div>
                    </div>
                  </PieChart>
                </Col>
              </Row>

              <Row className="blue textCenter bold marginHor20" gutter={16}>
                העלויות הינם בהתאם לדו"ח שמאי אחרון ו\או בהתאם למחירון יזם
                ונציגות
              </Row>
            </div>

            {/* <div>
            <div className="bold marginTop20">קבוצת הרכישה</div>
          </div> */}

            <div
              className="marginTop20"
              style={{ display: "flex", marginBottom: "195px" }}
            >
              <Col className="marginTop10" span={24}>
                <div className="bold full fright">מידע על המיזם:</div>
                <div className={"full fright"}>
                  {data.assets.ProjectDescription}
                </div>

                <Button
                  className="br-blue fright blue marginHor20 "
                  style={{
                    width: "100%",
                    height: "40px",
                    margin: "15px auto",
                    display: "block",
                  }}
                  onClick={(e) =>
                    this.showContactModal(data.assets.ProjectHash)
                  }
                >
                  צור קשר עם היזם
                </Button>
              </Col>
            </div>
            {MainStore.showContactModal ? <Contact /> : null}
          </div>
        </div>
      );
    } else {
      return <Skeleton active />;
    }
  }
}
export default withRouter(ProgectMain);
