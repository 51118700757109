import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { inject, observer } from "mobx-react";

import PaymentBadge from "../../../components/paymentBadge/main";
import GridCard from "../../../components/gridCard/main";

/* STYLES */
import "./main.css";

let InBudget = 0;
let OutBudget = 0;
let OpenDeposit = 0;
let RemainPayment = 0;

/* STORES */
let Dashboard;
let MainStore;
@inject("dashboard", "mainStore")
@observer
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentsreceiveds: [],
      totalPaymentsSum: 0,
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    let AssetsHash = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    MainStore.selectedAsset = AssetsHash;
    MainStore.assetsOne().then((results) => {
      let paymentsreceiveds = results[0].payments.paymentsreceiveds;

      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][prop]);
        }
        return total;
      };

      this.setState({
        paymentsreceiveds: paymentsreceiveds,
        totalPaymentsSum: paymentsreceiveds.sum("PaymentAmount").toFixed(2),
      });

      MainStore.selectedAssetData = results[0];
    });
  }

  /* EVENTS */
  render() {
    let { paymentsreceiveds, AssetCost, totalPaymentsSum } = this.state;
    let colorIndex = 0;
    let colors = [
      "#ff2e57",
      "#086b90",
      "#057aff",
      "#00538b",
      "#cc4c4c",
      "#60cb4c",
    ];

    let paymentBar = [];
    let paymentList = [];
    if (paymentsreceiveds !== undefined) {
      var sortedtImages = paymentsreceiveds,
        result = paymentsreceiveds.reduce(function (r, a) {
          r[a.PaymentSection] = r[a.PaymentSection] || [];
          r[a.PaymentSection].push(a);
          return r;
        }, Object.create(null));

      paymentList = Object.entries(result).map(([key, payment]) => {
        let InBudget = 0;
        let OutBudget = 0;
        let total = 0;
        let color = colors[colorIndex + 1];
        for (let i = 0; i < payment.length; i++) {
          if (payment[i].InBudget === "כן") {
            InBudget = InBudget + Number(payment[i].PaymentAmount);
          } else {
            OutBudget = OutBudget + Number(payment[i].PaymentAmount);
          }
        }

        total = InBudget + OutBudget;
        paymentBar.push({
          color: color,
          precenteg: (total * 100) / totalPaymentsSum,
        });

        colorIndex = colorIndex + 1;
        return (
          <GridCard
            type={"payment"}
            color={color}
            col1={((total * 100) / totalPaymentsSum).toFixed(0) + "%"}
            col2={Dashboard.numberWithCommas(total)}
            col3={key}
            OutBudget={OutBudget}
            InBudget={InBudget}
            totalPaymentsSum={totalPaymentsSum}
          />
        );
      });
    }
    return (
      <div className="container conatiner-bottom payments scroll-me">
        <div className="padding20">
          {paymentsreceiveds.length > 0 ? (
            <div>
              <Row className={"f1 bold show textCenter"}>
                {MainStore.selectedAssetData.assets.ProjectName}
              </Row>

              <Row className={"flex radius marginHor20"}>
                {paymentBar.map((item, key) => (
                  <div
                    className="bar-precent"
                    style={{
                      height: "35px",
                      width: item.precenteg + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                ))}
              </Row>

              <Row className="gray textCenter">
                {Dashboard.numberWithCommas(totalPaymentsSum)} &#8362;
              </Row>

              <Row gutter={24}>{paymentList}</Row>
            </div>
          ) : (
            <div className="textCenter bold black f1">
              אין תשלומים מעודכנים לפרוייקט
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Payments);
