import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Carousel } from "antd";

/* STYLES */
import "./main.css";

/* STORES */
class CarouselWidget extends Component {
  constructor(props) {
    super(props);
  }

  /* EVENTS */
  render() {
    let { images, style } = this.props;

    return (
      <div>
        {images.length > 1 ? (
          <Carousel>
            {images.map((image, i) => (
              <div>
                <img style={style} src={image.url}></img>
              </div>
            ))}
          </Carousel>
        ) : (
          <div>
            <img
              style={style}
              src={"https://pbs.twimg.com/profile_images/1421621283/cpa.JPG"}
            ></img>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(CarouselWidget);
