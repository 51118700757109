import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";
// COMPONENTS
import Header from "../../components/header/main";
import Alert from "../../components/alert/main";
import CardAddedFiles from "../../components/cardAddedFiles/main";
import Moment from "react-moment";

/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
let Dashboard;

@inject("mainStore", "dashboard")
@observer
class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      assetMedia: [],
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.getPaymentsByUser("paymentRequirements", {
      Hash: user.Hash,
    }).then((results) => {
      this.setState({ data: results });
    });

    MainStore.getAsstsByUser("assets", { Hash: user.Hash }).then((results) => {
      this.setState({ assetMedia: results });
    });
  }

  /* EVENTS */
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    let { data } = this.state;
    data = data.filter(
      (paymentRequest) => paymentRequest.payments.Open[0] === "פתוח"
    );
    return (
      <div>
        <Header
          back={false}
          title={`היי ${user.FirstName}!`}
          secoundery={<Moment format="DD/MM/YYYY">{user.lastUpdated}</Moment>}
        />

        <div className="Feed ">
          <div
            className="scrollbar b-cover"
            style={{
              backgroundImage: `url(
                "/img/back.png"
            )`,
            }}
          ></div>

          <div className="content  container top scroll  b-gray-t">
            {data.length > 0 && data[0].payments.PaymentAmount.length > 0 ? (
              <Alert
                amount={Dashboard.numberWithCommas(
                  data[0].payments.PaymentAmount[0]
                )}
                title={
                  data[0].payments.ProjectName[0] +
                  " " +
                  data[0].payments.ApartmentNumber[0]
                }
                secoundery={`להפקדה עד  ${data[0].payments.PaymentDate[0]}`}
                openPayments={data.length}
              />
            ) : null}
            <Col span={24} className="padding20">
              {data.length === 0 ||
              data[0].payments.PaymentAmount.length < 1 ? (
                <div className="f20">אין עדכונים חדשים</div>
              ) : null}

              {this.state.assetMedia.map((item, i) =>
                item.assets.ProjectImages !== undefined &&
                item.assets.ProjectImages.length > 0 ? (
                  <CardAddedFiles
                    hash={item.assets.AssetsHash}
                    type={"images"}
                    files={item.assets.ProjectImages}
                    barTitle={`נוספו ${item.assets.ProjectImages.length} קבצים חדשים`}
                    title={
                      item.assets.ProjectName +
                      "/" +
                      item.assets.ApartmentNumber
                    }
                    secoundery={
                      (item.assets.ApartmentNumber,
                      item.assets.Street,
                      item.assets.City)
                    }
                  />
                ) : null
              )}

              {this.state.assetMedia.map((item, i) =>
                item.assets.paymentsreceiveds !== undefined &&
                item.payments.paymentsreceiveds.length > 0 ? (
                  <CardAddedFiles
                    hash={item.assets.AssetsHash}
                    type={"files"}
                    files={item.payments.paymentsreceiveds}
                    barTitle={`דוחות חדשים נוספו בתאריך ${item.payments.paymentsreceiveds[0].PaymentDate}`}
                    title={
                      item.assets.ProjectName +
                      "/" +
                      item.assets.ApartmentNumber
                    }
                    secoundery={
                      (item.assets.ApartmentNumber,
                      item.assets.Street,
                      item.assets.City)
                    }
                  />
                ) : null
              )}
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Feed);
