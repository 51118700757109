import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Icon, message, Drawer, Upload, Input } from "antd";
import { inject, observer } from "mobx-react";
/* STYLES */
import "./main.css";
import { observable } from "mobx";

const { Dragger } = Upload;

/* STORES */
let FormStore;
let Dashboard;
let MainStore;
@inject("formStore", "dashboard", "mainStore")
@observer
class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: 100,
      prevImage: "",
      folder: ["מסחר", "אפיון"],
    };

    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  /* EVENTS */
  changImageAfterUploa = (image) => {
    this.setState({ prevImage: image });
  };

  updateFolderName(fileUrl, name) {
    let Hash = Dashboard.MediaPopHash;
    let Url = fileUrl;
    let folderName = name.trim();

    if (folderName !== "") {
      fetch(`${global.apiUrl}/media/foders/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Hash: Hash,
          Url: Url,
          folderName: folderName,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
        })
        .catch((error) => {});
    }
  }

  deleteImage(fileUrl, i) {
    let Hash = Dashboard.MediaPopHash;
    let Url = fileUrl;

    fetch(`${global.apiUrl}/media/image/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Hash: Hash,
        Url: Url,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {

        Dashboard.MediaImages.splice(i, 1);
      })
      .catch((error) => {});
  }

  extractFileName = (url) => {
    let decoded = "";
    if (url) {
      let file_name = url.split("/").pop();
      decoded = decodeURIComponent(file_name);
    }
    return decoded;
  };

  render() {
    return (
      <div className="flex media">
        <div style={{ width: "20%" }}>
          <div className="bold f3"> תמונה ראשית </div>
          <div clas="main-image-edit">
            <img
              src={
                Dashboard.MediaMainImage
                  ? Dashboard.MediaMainImage
                  : "/img/place.png"
              }
              className="full img-thumb"
            ></img>

            <Upload
              {...{
                name: "file",
                accept: ".png, .jpg, .jpeg",
                data: {
                  model: "progects",
                  Hash: Dashboard.MediaPopHash,
                  multiple: false,
                },
                multiple: false,
                action: `${global.apiUrl}/upload/savefiles/`,
                onChange(info) {
                  const { status } = info.file;
                  if (status !== "uploading") {
                    // this.setState({ prevImage: info.file.response });
                  }
                  if (status === "done") {
                    Dashboard.MediaMainImage = info.file.response;
                    message.success(`התמונה עודכנה בהצלחה`);
                  } else if (status === "error") {
                    message.error(`${info.file.name} ישנה בעיה בטעינת הקובץ`);
                  }
                },
              }}
            >
              <Button>
                <Icon type="upload" /> החלף תמונה
              </Button>
            </Upload>
          </div>
        </div>
        <div style={{ width: "80%", marginRight: "20px" }}>
          <div className="bold f3"> ספריית תמונות </div>
          <Dragger
            {...{
              name: "file",
              accept: ".png, .jpg, .jpeg, .pdf, .csv ,.doc",
              data: {
                model: "progects",
                Hash: Dashboard.MediaPopHash,
                multiple: true,
              },
              multiple: true,
              action: `${global.apiUrl}/upload/savefiles/`,
              onChange(info) {
                const { status } = info.file;
                if (status !== "uploading") {
                }
                if (status === "done") {
                  // Dashboard.MediaMainImage = info.file.response;
                  message.success(`התמונה עודכנה בהצלחה`);
                } else if (status === "error") {
                  message.error(`${info.file.name} ישנה בעיה בטעינת הקובץ`);
                }
              },
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              ניתן להוסיף תמונות בליחצה או גרירה
            </p>
            <p className="ant-upload-hint">תומך רק בקבצי תמונה</p>
          </Dragger>
          <div className="main-image-library">
            {Dashboard.MediaImages.map((item, i) => (
              <div
                className="img-wrapper"
                onMouseEnter={() => this.setState({ showDelete: i })}
                onMouseLeave={() => this.setState({ showDelete: 100 })}
              >
                {item.url.includes(".pdf") ? (
                  <a
                    href={item.url}
                    style={{ height: "145px" }}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-pdf" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(item.url)}
                    </div>
                  </a>
                ) : item.url.includes(".doc") ? (
                  <a
                    href={item.url}
                    style={{ height: "138px" }}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-word" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(item.url)}
                    </div>
                  </a>
                ) : item.url.includes(".csv") ? (
                  <a
                    href={item.url}
                    style={{ height: "138px" }}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-excel" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(item.url)}
                    </div>
                  </a>
                ) : (
                  <img src={item.url}></img>
                )}
                <Icon
                  onClick={(e) => this.deleteImage(item.url, i)}
                  type="delete"
                  className={
                    "full marginCenter f3 red cursor " +
                    (this.state.showDelete === i ? "" : "hidden")
                  }
                />

                <Input
                  onBlur={(e) =>
                    this.updateFolderName(item.url, e.target.value)
                  }
                  placeholder={
                    item.file !== undefined ? item.file : "שם התיקייה"
                  }
                ></Input>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Media);
