import { action, observable } from "mobx";
import axios from "axios";
axios.defaults.withCredentials = true;
export default class Dashboard {
  //   CHANGE PAYMENT TABLE
  @observable visible = false;
  @observable popUpModel = "";
  @observable popType = "";
  @observable qas = [];
  @observable assets = [];

  @observable mediaPopVisible = false;

  //media
  @observable MediaPopHash = "";
  @observable MediaImages = [];

  @observable MediaMainImage =
    "https://pbs.twimg.com/profile_images/1421621283/cpa.JPG";

  @action showPup(model) {
    this.visible = true;
    this.popUpModel = model;
  }

  @action hidePup(type, model) {
    this.visible = false;
  }

  @action showMediaPop() {
    this.mediaPopVisible = true;
  }

  @action hideMediaPop() {
    this.mediaPopVisible = false;
  }

  // we need to add userId
  @action getDashboardInfo() {
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/events/dashboard`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
         
          resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  // show enterpenoor contact info from any page
  @action media(hash = null) {
    let filters = hash !== null ? { Hash: hash } : {};
    return new Promise((resolve, reject) => {
      fetch(`${global.apiUrl}/media/images/all`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filters: { Hash: hash },
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    });
  }

  // numberWithCommas
  @action numberWithCommas(x) {
    if (x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } else {
      return null;
    }
  }
}
