import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Select, DatePicker, Button } from "antd";
// COMPONENTS
import Header from "../../../components/header/main";
/* STYLES */
import "./main.css";

/* VARIABLES */
const { Option } = Select;
const { RangePicker } = DatePicker;

/* STORES */
class Reports extends Component {
  constructor(props) {
    super(props);
  }

  /* EVENTS */
  render() {
    return (
      <div className="Report">
        <Row
          style={{ maxWidth: "450px", margin: "15px auto", display: "block" }}
        >
          <Col span={24} className="marginHor20">
            <RangePicker
              className="full"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={["Start Time", "End Time"]}
              onChange={this.onChange}
              onOk={this.onOk}
            />
          </Col>

          <Col span={24}>
            <Button
              className="br-blue b-blue white full fRight bold"
              style={{ width: "200px", height: "40px" }}
            >
              הפקת דו"ח
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(Reports);
