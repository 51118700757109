import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { inject, observer } from "mobx-react";
// COMPONENTS
import Header from "../../components/header/main";
import CardAsset from "../../components/cardAsset/main";
import Contact from "../../components/contact/main";
/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class FindAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showContactModal: false,
    };
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    MainStore.getAllByModelAndByUser("progects", {
      OpenForMarketing: "כן",
    }).then((results) => {
      this.setState({ data: results });
    });
  }

  /* EVENTS */
  render() {
    return (
      <div>
        <Header back={true} title={"פרוייקטים בשיווק"} />
        <div className="FindAsset">
          <div
            className="scrollbar b-cover"
            style={{
              backgroundImage: `url(
                "/img/back.png"
            )`,
            }}
          ></div>
          <div className="content container top scroll padding20 b-gray-t">
            <Row>
              {this.state.data.map((item, i) => (
                <Col span={24}>
                  <CardAsset
                    hash={item.Hash}
                    image={
                      item.Media.length > 0
                        ? item.Media[0].Url[0]
                        : "https://pbs.twimg.com/profile_images/1421621283/cpa.JPG"
                    }
                    title={item.ProjectName + "/" + item.StreetNumber}
                    secoundery={(item.StreetNumber, item.Street, item.City)}
                    decription={item.Description}
                  />
                </Col>
              ))}
            </Row>
          </div>
          {MainStore.showContactModal ? <Contact /> : null}
        </div>
      </div>
    );
  }
}
export default withRouter(FindAsset);
