import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Tabs, Icon } from "antd";
// COMPONENTS
import Header from "../../../components/header/main";

// PAGGES
import ProgectMain from "../progectMain/main";
import GalleryScreen from "../galleryScreen/main";
import MyDeposits from "../myDeposits/main";
import Reports from "../reports/main";
import Payments from "../payments/main";
import QA from "../qa/main";

/* STYLES */
import "./main.css";

// VARIABLES
const { TabPane } = Tabs;

/* STORES */
class ProgectWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "mail",
    };
  }

  /* EVENTS */

  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    return (
      <div>
        <Header back={true} title={`חזרה`} />
        <div
          className="ProgectMain b-cover"
          style={{
            backgroundImage: `url(
            "/img/back.png"
        )`,
            height: "100vh",
            width: "100%",
          }}
        >
          <div className="content container  top  b-gray">
            <Tabs tabPosition="bottom" defaultActiveKey="1" className="">
              <TabPane className="" tab={<Icon type="home" />} key="1">
                <div className="scroll">
                  <ProgectMain />
                </div>
              </TabPane>
              <TabPane
                className="padding20"
                tab={<Icon type="picture" />}
                key="2"
              >
                <div className="scroll">
                  <GalleryScreen />
                </div>
              </TabPane>

              <TabPane className="" tab={<Icon type="dollar" />} key="3">
                <div className="scroll">
                  <MyDeposits />
                </div>
              </TabPane>

              <TabPane
                className="padding20"
                tab={<Icon type="audit" />}
                key="4"
              >
                <div className="scroll">
                  <Payments />
                </div>
              </TabPane>

              <TabPane
                className="padding20"
                tab={<Icon type="question" />}
                key="5"
              >
                <div className="scroll">
                  <QA />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ProgectWrapper);
