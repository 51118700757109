import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { inject, observer } from "mobx-react";
import { IoLogoWhatsapp } from "react-icons/io";
// COMPONENTS
import Header from "../../components/header/main";
import Contact from "../../components/contact/main";

/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class MyAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showContactModal: false,
    };
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.getAsstsByUser("assets", { Hash: user.Hash }).then((results) => {
      this.setState({ data: results });
    });
  }

  showContactModal(hash, ProgetcName, AssetName) {
    MainStore.showContactModalFunc(hash);
    MainStore.ProgetcName = ProgetcName;
    MainStore.AssetName = AssetName;
  }
  renderAsset(item, i) {
    let ProgetcName = item.assets.ProjectName;
    let AssetName = `${item.assets.Street}  ${item.assets.StreetNumber} ${item.assets.City}`;
    return (
      <div className="marginHor20">
        <div className="flex ">
          <div className="flex1">
            <Link to={`/progect/${item.assets.AssetsHash}`} className="block">
              <span className={"f1 bold block"}>{item.assets.ProjectName}</span>
              <span className={"f3 block"}>
                {item.assets.Street} {item.assets.StreetNumber} ,{" "}
                {item.assets.City} , דירה {item.assets.ApartmentNumber}
              </span>
            </Link>
          </div>

          <Button
            className="br-green b-green"
            style={{ width: "60px" }}
            type="primary"
            size={"large"}
            onClick={(e) =>
              this.showContactModal(
                item.assets.ProjectHash,
                ProgetcName,
                AssetName
              )
            }
          >
            <IoLogoWhatsapp />
          </Button>
        </div>

        <Row>
          <Col span={24} className="marginTop10">
            {item.assets.ProjectMainImage !== undefined ? (
              <Link to={`/progect/${item.assets.AssetsHash}`}>
                <img
                  className="imageFull"
                  src={item.assets.ProjectMainImage[0]}
                ></img>
              </Link>
            ) : null}
          </Col>

          {/* <Col span={24}>
            <div className={"f3 bold"}> לב תל אביב / 6</div>
          </Col> */}
        </Row>
      </div>
    );
  }

  /* EVENTS */
  render() {
    let { data } = this.props;
    return (
      <div>
        <Header back={true} title={"הנכסים שלי"} />

        <div
          className="scrollbar b-cover"
          style={{
            backgroundImage: `url(
                "/img/back.png"
            )`,
          }}
        ></div>

        <div className="MyAssets">
          <div className="content container top scroll padding20 b-gray-t">
            {this.state.data.length > 0 ? (
              this.state.data.map((item, i) => this.renderAsset(item, i))
            ) : (
              <div className="f20">אין נכסים משוייכים</div>
            )}
          </div>

          {MainStore.showContactModal ? <Contact /> : null}
        </div>
      </div>
    );
  }
}
export default withRouter(MyAssets);
