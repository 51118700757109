import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Avatar, Input, Switch, Icon, Button } from "antd";
// COMPONENTS
import Header from "../../components/header/main";
/* STYLES */
import "./main.css";

/* STORES */
class Settings extends Component {
  constructor(props) {
    super(props);
  }

  /* EVENTS */
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    return (
      <div>
        <Header back={true} title={"הגדרות"} />
        <div className=" Settings">
          <div
            className="scrollbar b-cover"
            style={{
              backgroundImage: `url(
                "/img/back.png"
            )`,
            }}
          ></div>

          <div className="content container top padding20 b-gray-t ">
            <Row className="marginHor20">
              <Col span={24}>
                <div className={"f4 bold"}>שם מלא:</div>
                <div className={"f4"}>
                  {user.FirstName + " " + user.LastName}
                </div>
              </Col>

              <Col span={24}>
                <div className={"f4 bold"}>טלפון:</div>
                <div className={"f4"}>{user.PhoneNumber}</div>
              </Col>

              <Col span={24}>
                <div className={"f4 bold"}>אימייל:</div>
                <div className={"f4"}>{user.Email}</div>
              </Col>
            </Row>

            {/* <Row className="marginHor20">
            <Col span={24}>
              <div className={"f4 bold"}>אפשר קבלת עידכונים</div>
            </Col>
          </Row>
          <Row>
            <Col span={6} className="fright">
              <Switch
                className="fright"
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked
              />
              <div className={"f4 paddingVer20"}>באימייל</div>
            </Col>

            <Col span={6} className="fright">
              <Switch
                className="fright"
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked
              />
              <div className={"f4 paddingVer20"}>בנייד</div>
            </Col>
          </Row> */}

            {/* <Row>
            <Button
              className="br-blue b-blue full white marginHor20 "
              style={{
                height: "40px",
                margin: "15px auto",
                display: "block"
              }}
            >
              שמור
            </Button>
          </Row> */}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Settings);
