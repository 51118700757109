import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "antd";
// COMPONENTS
import Header from "../../components/header/main";

/* STYLES */
import "./main.css";

/* STORES */
class MyProjects extends Component {
  constructor(props) {
    super(props);
  }

  /* EVENTS */
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    return (
      <div>
        <Header back={true} title={`היי ${user.FirstName}!`} />

        <div
          className="scrollbar b-cover"
          style={{
            backgroundImage: `url(
                "/img/back.png"
            )`,
          }}
        ></div>

        <div className="container MyProjects">
          <div className="content">MyProjects</div>
        </div>
      </div>
    );
  }
}
export default withRouter(MyProjects);
