import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Card, Button, Icon } from "antd";
import { inject, observer } from "mobx-react";
import { FiImage, FiArrowLeft } from "react-icons/fi";

/* STYLES */
import "./main.css";
import Contact from "../../components/contact/main";
/* STORES */
let MainStore;
@inject("mainStore")
@observer
class CardAddedFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      data: [],
      showContactModal: false,
    };

    MainStore = this.props.mainStore;
  }

  /* EVENTS */
  showDescription() {
    this.setState({
      display: !this.state.display,
    });
  }

  showContactModal() {
    MainStore.showContactModal = true;
  }

  render() {
    let {
      type,
      files,
      barTitle,
      image,
      title,
      secoundery,
      decription,
      hash,
    } = this.props;
    return (
      <div className="CardAddedFiles marginHor20">
        <Row>
          <Col span={24}>
            <Row>
              <Col className="inline textLeft" span={2}>
                <Link className={"show contents black"} to={`/progect/${hash}`}>
                  <div className={"f4"}>צפה</div>
                  <FiArrowLeft size={22} />
                </Link>
              </Col>

              <Col span={8} offset={12}>
                <div>
                  <div className={"f4 bold"}>{title}</div>
                  <div className={"f4"}>{secoundery}</div>
                </div>
              </Col>

              <Col span={1} offset={1}>
                <FiImage size={22} />
              </Col>
            </Row>
          </Col>
          <Col span={22}>
            <Link className={"show contents black"} to={`/progect/${hash}`}>
              <div className="b-white padding20 marginHor10">{barTitle}</div>
              {files.length > 3 ? (
                <div className={type === "images" ? "show hoverCard" : "hide"}>
                  {files.length - 4}
                </div>
              ) : null}
              {type === "images"
                ? files.map((item, i) =>
                    i < 4 ? (
                      <img
                        className="radius"
                        style={{
                          width: "25%",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={
                          item.url.includes(".pdf") ? "img/pdf2.png" : item.url
                        }
                        className=""
                      ></img>
                    ) : null
                  )
                : null}
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(CardAddedFiles);
