import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Icon, Modal } from "antd";

/* STYLES */
import "./main.css";

/* STORES */
class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  /* EVENTS */
  render() {
    let { amount, title, secoundery, openPayments } = this.props;
    return (
      <div className="b-red padding20 white">
        <Link className="white" to={`/payment-requirements/`}>
          <Row>
            <Col
              className="f1 textLeft padding10 "
              onClick={this.showModal}
              span={12}
            >
              {amount} ש"ח
            </Col>
            <Col span={12}>
              <div
                className={
                  "f1 textRight bold" +
                  (secoundery !== undefined ? null : " marginTop10")
                }
              >
                {title}
              </div>
              {secoundery !== undefined ? <div>{secoundery}</div> : null}
            </Col>

            {openPayments > 1 ? (
              <Col span={24}>
                <div className={"f5 textRight textCenterbold"}>
                  + {openPayments - 1} תשלומים פתוחים
                </div>
              </Col>
            ) : null}
          </Row>
        </Link>
      </div>
    );
  }
}
export default withRouter(Alert);
