import { Button, Icon, message, Upload } from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

const { Dragger } = Upload;

/* STORES */

let Dashboard;
@inject("dashboard")
@observer
class fileUploadDrag extends Component {
  @observable displayErrorMessage = false;
  @observable displaySuccessMessage = false;
  @observable docs = {};
  @observable errors = {};

  constructor(props) {
    super(props);
    Dashboard = this.props.dashboard;
    let thisRow = this;
    this.properties = {
      name: "file",
      accept: this.props.accept,
      data: {
        model: this.props.model,
        Hash: this.props.Hash,
        multiple: this.props.multiple,
      },
      multiple: this.props.multiple,
      action: this.props.url,
      onChange: (info) => {
        this.displaySuccessMessage = false;
        this.displayErrorMessage = false;
        this.docs = {};
        this.errors = {
          errorRowsCount: 0,
          invalidPhonNumber: [],
          isExitInDb: [],
          invalidRows: [],
        };

        const { status } = info.file;
        const errors = info.file.response ? info.file.response.errors : null;
        const docs = info.file.response ? info.file.response.docs : null;

        if (status === "done") {
          this.displaySuccessMessage =
            errors.errorRowsCount === 0 &&
            (errors.invalidPhonNumber && errors.invalidPhonNumber.length === 0) &&
            (errors.isExitInDb && errors.isExitInDb.length === 0) &&
            (errors.invalidRows && errors.invalidRows.length === 0) &&
            (errors.duplicatesRows && errors.duplicatesRows.length === 0) &&
            (errors.assetsHasNoExistInDb && errors.assetsHasNoExistInDb.length === 0);
          this.displayErrorMessage = !this.displaySuccessMessage;
          this.docs = docs;
          this.errors = errors;

          thisRow.props.onFinishUpload();
        } else if (status === "error") {
          message.error(`${info.file.name} ישנה בעיה בטעינת הקובץ`);
        }
      },
    };
  }

  componentDidMount = () => {
    this.displayErrorMessage = false;
    this.displaySuccessMessage = false;
  };

  render() {
    return (
      <>
        <Dragger {...this.properties}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            ניתן למשוך לכאן את הקובץ המבוקש, או ללחוץ להפעלה
          </p>
          <p className="ant-upload-hint">
            תומך רק בקבצי {this.props.acceptNames}
          </p>
        </Dragger>
        <div>
          {this.displaySuccessMessage && (
            <>
              <div className="bold green">הטבלה עודכנה בהצלחה</div>
              {/* {this.docs && <div className="green marginTop20">
                {this.docs.length} עודכנו בהצלחה
                </div>} */}
            </>
          )}
          {this.displayErrorMessage && (
            <>
              <div className="red marginTop20">
                הקובץ לא נטען ,
              </div>
              {this.errors.invalidPhonNumber &&
                this.errors.invalidPhonNumber.length > 0 ? (
                <div className=" marginTop20">
                  <span className="red ">מספר טלפון לא תקין/נמצא במערכת :</span>
                  <div className="error-row-duplicates">
                    {this.errors.invalidPhonNumber.map((rowNumber) => {
                      return <div> שורה מספר: {rowNumber}</div>;
                    })}
                  </div>
                </div>
              ) : null}

              {this.errors.isExitInDb && this.errors.isExitInDb.length > 0 ? (
                <div className="marginTop5 ">
                  <div className="marginVer5 red"> מידע כפול בשורה:</div>
                  <div className="error-row-duplicates">
                    {this.errors.isExitInDb.map((rowNumber) => {
                      return <div> שורה מספר: {rowNumber}</div>;
                    })}
                  </div>
                </div>
              ) : null}

              {this.errors.invalidRows && this.errors.invalidRows.length > 0 ? (
                <div className="marginTop5 ">
                  <div className="marginVer5 red"> שורה עם מידע שגוי\חסר:</div>
                  <div className="error-row-duplicates">
                    {this.errors.invalidRows.map((rowNumber) => {
                      return <div> שורה מספר: {rowNumber}</div>;
                    })}
                  </div>
                </div>
              ) : null}

              {this.errors.assetsHasNoExistInDb &&
                this.errors.assetsHasNoExistInDb.length > 0 ? (
                <div className="marginTop5 ">
                  <div className="marginVer5 red">נכס לא נמצא במערכת:</div>
                  <div className="error-row-duplicates">
                    {this.errors.assetsHasNoExistInDb.map((rowNumber) => {
                      return <div> שורה מספר: {rowNumber}</div>;
                    })}
                  </div>
                </div>
              ) : null}
              {this.errors.duplicatesRows &&
                this.errors.duplicatesRows.length > 0 ? (
                <div className="marginTop5 ">
                  <div className="marginVer5 red">המידע נמצא במערכת:</div>
                  <div className="error-row-duplicates">
                    {this.errors.duplicatesRows.map((rowNumber) => {
                      return <div> שורה מספר: {rowNumber}</div>;
                    })}
                  </div>
                </div>
              ) : null}
            </>
          )}
          <Button
            className="marginCenter marginTop20 show"
            key="back"
            onClick={this.props.closePup}
          >
            סגור
          </Button>
        </div>
      </>
    );
  }
}
export default withRouter(fileUploadDrag);
