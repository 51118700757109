import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Icon, Modal } from "antd";

/* STYLES */
import "./main.css";

/* STORES */
class header extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  back = () => {
    this.props.history.push("/");
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  Logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  /* EVENTS */
  render() {
    let { back, title, secoundery } = this.props;
    return (
      <div className="header">
        <Row>
          <Col onClick={this.showModal} span={2}>
            <Icon className="f1 bold blue cursor paddingHor20" type="menu" />
          </Col>
          <Col span={22}>
            {back ? (
              <Row>
                <Col span={22}>
                  <div
                    className={
                      "f1 bold" +
                      (secoundery !== undefined ? null : " marginTop10")
                    }
                  >
                    {title}
                  </div>
                  {secoundery !== undefined ? <div>{secoundery}</div> : null}
                </Col>
                <Col onClick={this.back} span={2}>
                  <Icon
                    className="f1 bold blue cursor paddingHor20"
                    type="right"
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  className={
                    "f1 bold" +
                    (secoundery !== undefined ? null : " marginTop10")
                  }
                >
                  {title}
                </div>
                {secoundery !== undefined ? (
                  <div>כניסה אחרונה בתאריך {secoundery}</div>
                ) : null}
              </div>
            )}
          </Col>
        </Row>

        <Modal
          className="menu-modal"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className="f1 bold white textCenter">מה תרצה לעשות היום</div>
          <Link to="/"></Link>
          <Link to="/my-assets">הנכסים שלי</Link>
          <Link to="/payment-requirements">דרישות תשלום</Link>
          <Link to="/find-asset">פרוייקטים בשיווק</Link>
          <Link to="/settings">הגדרות</Link>
          <a to="#" onClick={(e) => this.Logout()}>
            התנתק
          </a>
        </Modal>
      </div>
    );
  }
}
export default withRouter(header);
