import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Button, Card, Icon, Modal } from "antd";
import { inject, observer } from "mobx-react";
/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class PaymentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      chhoseArray: [],
      type: "",
    };
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    // store choose to local storeg
    let chooseArray = JSON.parse(localStorage.getItem("amin_choo"))
      ? JSON.parse(localStorage.getItem("amin_choo"))
      : {};

    this.setState({
      chhoseArray: chooseArray,
    });
  }
  // model

  showModal = (Hash, type, text, ProjectName, ApartmentNumber) => {
    this.setState({
      type: text,
    });
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.newEvent({
      UserHash: user.Hash,
      UserName: user.FirstName + " " + user.LastName,
      Type: `לחיצה בעמוד תשלום`,
      Message: `לחיצה בעמוד תשלום | ${text}`,
      ProgetcName: ProjectName,
      AssetName: `${ApartmentNumber} ${ProjectName}`,
    }).then((results) => {
    });

    // store choose to local storeg
    let chooseArray = JSON.parse(localStorage.getItem("amin_choo"))
      ? JSON.parse(localStorage.getItem("amin_choo"))
      : {};

    chooseArray[Hash] = type;

    // Put the object into storage
    localStorage.setItem("amin_choo", JSON.stringify(chooseArray));

    this.setState({
      visible: true,
      chhoseArray: chooseArray,
    });

  };

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  /* EVENTS */
  render() {
    let {
      paymentOption,
      paymentsSteps,
      amount,
      paymentsVat,
      PaymentDate,
      ApartmentNumber,
      ProjectName,
      ProjectFile,
      Hash,
    } = this.props;
    return (
      <div className="PaymentRequest marginHor20">
        <Card>
          <Row>
            <Col className="textLeft" span={10}>
              <div className="f3 bold red2">{amount} ש"ח</div>
              {paymentsVat ? <div className="f4 bold">מע"מ</div> : null}
            </Col>

            <Col span={14}>
              <div className={"f3 bold"}>
                {ProjectName} / {ApartmentNumber}
              </div>
              <div className={"f4"}>להפקדה עד {PaymentDate}</div>
            </Col>

            {paymentOption.length > 1 ? (
              <Col span={24} className="marginTop10">
                <div className="textCenter marginTop10 bold">
                  אנא בחר דרך תשלום מועדפת
                </div>
                <Row className="marginHor20">
                  <Col span={10}>
                    <Button
                      onClick={(e) =>
                        this.showModal(
                          Hash,
                          1,
                          `הון עצמי ${ProjectName} - ${ApartmentNumber}`,
                          ProjectName,
                          ApartmentNumber
                        )
                      }
                      className={
                        "br-red2 red2 fLeft bold " +
                        (this.state.chhoseArray[Hash] === 1
                          ? "active_payment_btn"
                          : "")
                      }
                      style={{ width: "100%", height: "40px" }}
                    >
                      הון עצמי
                    </Button>
                  </Col>

                  <Col span={4} className="textCenter bold">
                    או
                  </Col>

                  <Col span={10}>
                    <Button
                      onClick={(e) =>
                        this.showModal(
                          Hash,
                          2,
                          `אשראי ${ProjectName} - ${ApartmentNumber}`,
                          ProjectName,
                          ApartmentNumber
                        )
                      }
                      className={
                        "br-red2 red2 fLeft bold " +
                        (this.state.chhoseArray[Hash] === 2
                          ? "active_payment_btn"
                          : "")
                      }
                      style={{ width: "100%", height: "40px" }}
                    >
                      אשראי
                    </Button>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Row>
                <Col span={24}>
                  <Button
                    onClick={(e) =>
                      this.showModal(
                        Hash,
                        3,
                        `הון עצמי ${ProjectName} - ${ApartmentNumber}`,
                        ProjectName,
                        ApartmentNumber
                      )
                    }
                    className="br-red2 b-red2 white marginHor20 "
                    style={{
                      width: "100%",
                      height: "40px",
                      margin: "15px auto",
                      display: "block",
                    }}
                  >
                    {paymentOption !== undefined ? paymentOption[0] : null}
                  </Button>
                </Col>
              </Row>
            )}
            {ProjectFile !== undefined && ProjectFile.length > 0 ? (
              <Col span={24}>
                <a
                  href={ProjectFile}
                  className="textCenter black full show marginTop10 bold"
                >
                  <Icon type="arrow-down" className="marginVer20" />
                  לחץ כאן להורדת פירוט הדרישה
                </a>
              </Col>
            ) : null}
          </Row>
        </Card>

        <Modal
          className="payment-req"
          visible={this.state.visible}
          footer={null}
        >
          <div className="f4   textCenter">
            תודה פנייתך התקבלה, הפקדה בסך {amount} ש"ח תבוצע ממקורות{" "}
            {this.state.type} עד {PaymentDate}{" "}
          </div>

          <Button
            onClick={(e) => this.handleCancel()}
            className="br-blue b-blue white marginHor20 "
            style={{
              width: "100%",
              height: "40px",
              margin: "15px auto",
              display: "block",
            }}
          >
            סגור
          </Button>
        </Modal>
      </div>
    );
  }
}
export default withRouter(PaymentRequest);
