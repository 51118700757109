import {
  Button,
  Drawer,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Table,
} from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import Highlighter from "react-highlight-words";
import { withRouter } from "react-router-dom";
import reqwest from "reqwest";
import DinamicForm from "../../components/dinamicForm/main";
import FileUploadDrag from "../../components/fileUploadDrag/main";
import ListView from "../../components/listView/main";
import Media from "../../components/media/main";
import QaEdit from "../../components/qaEdit/main";
import "./main.css";

const { parse } = require("json2csv");
const { Option } = Select;

let FormStore;
let Dashboard;
let MainStore;

@inject("formStore", "dashboard", "mainStore")
@observer
class TableScreen extends React.Component {
  @observable data;
  @observable urlDownloadCsv;

  terms = {};
  pagination = { current: 0 };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editingKey: "",
      visibleAdd: false,
      Drawervisible: false,
      DrawerRow: [],
      visibleUpload: false,
      total: "",
      searchText: "",
      searchedColumn: "",
      originalColumns: [],
      keyError: null,
      Hash: "",
      selectedRowKeys: [],
    };

    this.columns = [];
    FormStore = this.props.formStore;
    Dashboard = this.props.dashboard;
    MainStore = this.props.mainStore;
  }

  componentDidMount() {
    this.fetch({ page: 0 });

    this.setState({
      originalColumns: this.props.columns,
    });
    for (let i = 0; i < this.props.columns.length; i++) {
      let search = this.props.columns[i].search
        ? this.getColumnSearchProps(this.props.columns[i].dataIndex)
        : "";

      let cel = {
        title: this.props.columns[i].title,
        dataIndex: this.props.columns[i].dataIndex,
        editable: this.props.columns[i].editable,
        editableType: this.props.columns[i].editableType,
        className:
          this.props.columns[i].className !== undefined
            ? this.props.columns[i].className
            : "",
        ...search,

        render:
          this.props.columns[i].render !== undefined
            ? this.props.columns[i].render
            : "",
      };

      if (this.props.columns[i].display !== false) {
        this.columns.push(cel);
      }
    }
  }

  // search data

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={``}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="חיפוש"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          חיפוש
        </Button>
        <Button
          onClick={() => this.handleReset()}
          size="small"
          style={{ width: 90, height: 31 }}
        >
          אפס
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : "" }} />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    let searchTerm = selectedKeys[0] !== undefined ? selectedKeys[0] : "";

    this.terms = {
      ...this.terms,
      [dataIndex]: searchTerm.trim(),
    };

    this.pagination = {
      current: 1,
      pageSize: 10,
    };
    this.handleTableChange(this.pagination, this.terms);
    // this.url = `${global.apiUrl}/${this.props.model}/search`;
    // this.data = {
    //   regex: searchTerm.trim(),
    //   dataIndex: dataIndex,
    // };

    // fetch(`${global.apiUrl}/${this.props.model}/search`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(this.data),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     const pagination = { ...this.state.pagination };
    //     pagination.total = responseJson.sum;

    //     this.setState({
    //       loading: false,
    //       data: responseJson.items,
    //       total: responseJson.sum,
    //       pagination,
    //     });
    //   });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    this.pagination = pagination;
    this.filters = filters;
    this.sorter = sorter;
    if (pagination.current !== this.state.pagination.total) {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
      });
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current - 1,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        terms: this.terms,
      });
    }
  };

  // get data
  fetch = (params = {}) => {
    this.setState({ loading: true });

    this.url = `${global.apiUrl}/${this.props.model}/all`;


    reqwest({
      url: `${global.apiUrl}/${this.props.model}/all`,
      method: "post",
      data: {
        results: 10,
        ...params,
      },
      type: "json",
    }).then((data) => {
      const pagination = { ...this.state.pagination };

      pagination.total = data.sum;
      this.setState({
        loading: false,
        data: data.items,
        total: data.sum,
        pagination,
      });
    });
  };

  handleDownloadCsv = () => {
    fetch(this.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...this.data, downloadCsv: true }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        function download(filename, text) {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(text)
          );
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }

        let fileds = [];

        if (this.props.model === "deposits") {
          // deposits
          fileds = [
            "paymentID",
            "ProjectName",
            "City",
            "Street",
            "StreetNumber",
            "ApartmentFloor",
            "ApartmentNumber",
            "PaymentDate",
            "PaymentAmount",
            "CashMust",
            "InBudget",
            "IsVat",
          ];
        } else if (this.props.model === "paymentsReceived") {
          // payment recivied
          fileds = [
            "ProjectName",
            "City",
            "Street",
            "StreetNumber",
            "ApartmentNumber",
            "PaymentDate",
            "PaymentAmount",
            "CashCredit",
            "PaymentSection",
            "InBudget",
          ];
        } else if (this.props.model === "users") {
          // users
          fileds = ["FirstName", "LastName", "Email", "PhoneNumber", "Role"];
        }

        let items = responseJson.items.map((item) => {
          Object.keys(item).forEach((key) => {
            if (!fileds.includes(key)) {
              delete item[key];
            }
          });
          return item;
        });

        const csv = parse(items);
        download(`${this.props.model}.csv`, csv);
      });
  };

  handleReset = () => {
    this.terms = {};
    this.fetch({ page: 0 });
  };

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
      });

      // let HashArray = selectedRows.map(function (el) {
      //   return el.Hash !== undefined ? el.Hash : el._id;
      // });

      let HashArray = selectedRows.map(function (el) {
        return el._id;
      });

      FormStore.HashArray = HashArray;
    },
  };

  // drawer
  showDrawer = (record) => {
    let originalColumns = this.state.originalColumns;

    originalColumns.unshift(record.Hash);
    Object.entries(record).forEach(([key, value], index) => {
      Object.entries(originalColumns).forEach(([name, val], i) => {
        if (originalColumns[i]["dataIndex"] === key) {
          originalColumns[i].value = record[key];
        }
      });
    });

    this.setState({
      DrawerRow: originalColumns,
    });
  };

  closeDrawer = () => {
    MainStore.showDrawerModal = false;
  };

  // modal
  handleOk(model) {
    const { count, data } = this.state;

    MainStore.defaultValue = "";
    if (Dashboard.popUpModel === "add") {
      FormStore.uploadByModelAndByUser(model)
        .then((results) => {
          if (results.length > 0) {
            Dashboard.hidePup();

            this.setState({
              data: [results[0], ...data],
              count: count + 1,
            });

            message.success("הרשומה נוספה");
            this.fetch({ page: 0 });

            this.closeDrawer();
          } else if (results.errors.PhoneNumber) {
            message.error("מספר הטלפון  כבר קיים במערכת");
          } else if (results.errors.Hash) {
            message.error("הרשומה כבר קיימת במערכת");
          }
        })
        .catch((error) => {
          message.error("הרשומה לא נוצרה");
        });
    } else {
      Dashboard.hidePup();
    }
  }

  // delete

  deleteModelByHash(model) {
    let selectedRowKeys = this.state.selectedRowKeys;
    let data = this.state.data;
    FormStore.deleteModelByHash(model).then((responseJson) => {
      data = data.filter(function (value, index) {
        return selectedRowKeys.indexOf(index) == -1;
      });

      message.success(`${selectedRowKeys.length} רישומים נמחקו`);

      this.setState({
        data: data,
      });

      const page = Math.max(0, (this.state.pagination.current || 0) - 1);

      this.fetch({ page });
    });
  }

  showPup(popTtype, modal, bollean) {
    MainStore.actionButtonShow = bollean;
    Dashboard.showPup(popTtype, modal);
  }

  closePup() {
    Dashboard.hidePup();
  }

  renderPop() {
    switch (Dashboard.popUpModel) {
      case "add":
        return (
          <DinamicForm columns={this.props.columns} model={this.props.model} />
        );
      case "excel":
        return (
          <FileUploadDrag
            multiple={false}
            url={`${global.apiUrl}/upload/csv/`}
            model={this.props.model}
            Hash={this.state.Hash}
            accept={
              ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            }
            acceptNames={"אקסל"}
            onFinishUpload={this.handleReset}
            closePup={this.closePup}
          />
        );

      case "pdf":
        return (
          <FileUploadDrag
            multiple={false}
            url={`${global.apiUrl}/upload/savefiles/`}
            model={this.props.model}
            Hash={this.state.Hash}
            accept={"application/pdf"}
            acceptNames={"pdf"}
            onFinishUpload={this.handleReset}
          />
        );
      case "qa":
        return (
          <QaEdit
            url={`${global.apiUrl}/progects/qa/`}
            model={this.props.model}
            Hash={this.state.Hash}
          />
        );

      case "assets":
        return <ListView items={Dashboard.assets} Hash={this.state.Hash} />;
    }
  }

  // form validation
  validation(input, element, value, validationType, i) {
    this.setState({
      keyError: i,
    });

    FormStore.modelObject[input.dataIndex] = value;
    FormStore.generalEroor = "";

    if (value.length !== 0 || value !== "") {
      element.required = false;
    } else {
      element.required = true;
    }

    for (let i = 0; i < validationType.length; i++) {
      switch (validationType[i]) {
        case "Required":
          FormStore.Required(value);
          break;
        case "TextLength":
          FormStore.TextLength(value);
          break;
        case "Email":
          FormStore.Email(value);
          break;
        case "Phone":
          FormStore.Phone(value);
          break;
        case "Date":
          FormStore.Date(value);
          break;
      }
    }
  }

  validationError(validationType) {
    let formError;
    for (let i = 0; i < validationType.length; i++) {
      switch (validationType[i]) {
        case "Required":
          formError = FormStore.errorRequired;
          break;
        case "TextLength":
          formError = FormStore.errorTextLength;
          break;

        case "Email":
          formError = FormStore.errorEmail;
          break;

        case "Phone":
          formError = FormStore.errorPhone;
          break;

        case "Date":
          formError = FormStore.errorDate;
          break;
      }
    }

    return formError;
  }

  // drawer submit
  updateRecord(model, id) {
    debugger;
    fetch(`${global.apiUrl}/upload/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: model,
        id: id,
        modelObject: { ...FormStore.modelObject, _id: id },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });

    const page = Math.max(0, (this.state.pagination.current || 0) - 1);
    this.fetch({ page });

    this.closeDrawer();
  }

  render() {
    let { DrawerRow, originalColumns, keyError } = this.state;

    const columns = this.columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editableType: col.editableType,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return (
      <div className="conatiner-dashboard">
        {" "}
        <Button
          type="link"
          onClick={(e) => this.showPup("add", this.props.model, true)}
          style={{ marginBottom: 16 }}
          className="circle br-green"
        >
          <Icon className="f4 green  cursor " type="plus" />
        </Button>
        <Button
          type="link"
          onClick={(e) => this.showPup("excel", this.props.model, false)}
          style={{ marginBottom: 16 }}
          className="circle"
        >
          <Icon className="f4 green  cursor" type="file-excel" />
        </Button>
        <Modal
          title="הוספה"
          className="add-record"
          visible={Dashboard.visible}
          closeIcon={<Icon type="close" className="f3 black" />}
          cancelText={"סגור"}
          okText={"הוסף"}
          destroyOnClose={true}
          forceRender={true}
          footer={
            MainStore.actionButtonShow
              ? [
                  <Button key="back" onClick={(e) => Dashboard.hidePup()}>
                    סגור
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={(e) => this.handleOk(this.props.model)}
                  >
                    שמור
                  </Button>,
                ]
              : null
          }
          onOk={(e) => this.handleOk(this.props.model)}
          onCancel={(e) => Dashboard.hidePup()}
        >
          {this.renderPop()}
        </Modal>
        <Modal
          destroyOnClose={true}
          title="תמונות הפרויקט"
          visible={Dashboard.mediaPopVisible}
          closeIcon={<Icon type="close" className="f4 black" />}
          cancelText={"סגור"}
          width={800}
          footer={null}
          onCancel={(e) => Dashboard.hideMediaPop()}
        >
          <Media />
        </Modal>
        <Drawer
          title="עריכה"
          placement="right"
          closable={true}
          onClose={this.closeDrawer}
          visible={MainStore.showDrawerModal}
        >
          <div>
            {}
            {Object.keys(DrawerRow).map((keyName, i) =>
              DrawerRow[keyName].editable ? (
                DrawerRow[keyName].addRender !== undefined ? (
                  <div>
                    <label className="full bold">
                      {DrawerRow[keyName].title}
                    </label>
                    {DrawerRow[keyName].addRender}
                  </div>
                ) : (
                  <div>
                    <label className="full bold">
                      {DrawerRow[keyName].title}
                    </label>
                    <Input
                      className="marginTop10"
                      type={DrawerRow[keyName].type}
                      required={DrawerRow[keyName].required ? "required" : null}
                      key={i}
                      disabled={DrawerRow[keyName].disabled ? true : false}
                      onChange={(e) =>
                        this.validation(
                          DrawerRow[keyName],
                          e.target,
                          e.target.value,
                          DrawerRow[keyName].validation,
                          i
                        )
                      }
                      placeholder={DrawerRow[keyName].value}
                    />
                    {keyError === i ? (
                      <div className="error">
                        {this.validationError(DrawerRow[keyName].validation, i)}
                      </div>
                    ) : null}
                  </div>
                )
              ) : null
            )}

            <Button
              className="b-blue white full marginHor20"
              onClick={(e) => {
                let id = DrawerRow.filter(
                  (key) => typeof key === "object"
                ).find((record) => (record.dataIndex = "_id"));

                this.updateRecord(this.props.model, id.value);
              }}
            >
              שמור
            </Button>
          </div>
        </Drawer>
        <Table
          bordered={true}
          tableLayout={"fixed"}
          dataSource={this.state.data}
          columns={columns}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.loading}
          rowSelection={this.rowSelection}
          onChange={this.handleTableChange}
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
          rowKey="_id"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                this.setState({ Hash: record.Hash });
                MainStore.rowHash = record.Hash;
                // if (rowIndex === "assets") {
                //   MainStore.getAseetsListByUser();
                // }
                MainStore.getAseetsListByUser(true);

                this.showDrawer(record);
              },
            };
          }}
          title={() => (
            <div>
              <Select
                onChange={(e) => (FormStore.deleteRows = e)}
                defaultValue="defualt"
                style={{ width: 200 }}
              >
                <Option value="defualt">עריכה קבוצתית</Option>
                <Option value="delete">מחק</Option>
              </Select>

              <Button
                type="primary"
                onClick={() => this.deleteModelByHash(this.props.model)}
                icon="חיפוש"
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                בצע
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex">
              <div className="flex1"> נמצאו {this.state.total} רישומים</div>
              <div className="" onClick={this.handleDownloadCsv}>
                {this.props.model === "paymentsReceived" ||
                this.props.model === "deposits" ||
                this.props.model === "users" ? (
                  <Button type="link" className="circle">
                    הורד
                    <Icon
                      className="f4 cursor marginRight5"
                      type="file-excel"
                    />
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default withRouter(TableScreen);
