import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";
// COMPONENTS
import Header from "../../components/header/main";
import PaymentRequest from "../../components/paymentRequest/main";

/* STYLES */
import "./main.css";
import { observable } from "mobx";

/* STORES */
let MainStore;
let Dashboard;
@inject("mainStore", "dashboard")
@observer
class PaymentRequirements extends Component {
  @observable paymentRequerment;
  constructor(props) {
    super(props);
    this.state = {
      showContactModal: false,
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    MainStore.getPaymentsByUser("paymentRequirements", {
      Hash: user.Hash,
    }).then((results) => {
      this.paymentRequerment = results;
    });

    MainStore.newEvent({
      UserHash: user.Hash,
      UserName: user.FirstName + " " + user.LastName,
      Type: "צפייה בעמוד תשלום",
      Message: "צפייה בעמוד תשלום",
    }).then((results) => {
    });
  };

  /* EVENTS */
  render() {
    return (
      <div>
        <Header back={true} title={"דרישות תשלום"} />
        <div className="PaymentRequirements">
          <div
            className="scrollbar b-cover"
            style={{
              backgroundImage: `url(
                "/img/back.png"
            )`,
            }}
          ></div>
          <div className="content container top scroll padding20 b-gray-t">
            {this.paymentRequerment && this.paymentRequerment.length > 0 ? (
              <Row>
                {this.paymentRequerment &&
                  this.paymentRequerment.map((item, i) => {
                    return item.payments.Open[0] === "פתוח" ? (
                      <Col span={24}>
                        <PaymentRequest
                          paymentOption={
                            item.payments.CashMust[0] == "שניהם"
                              ? ["אשראי", "הון עצמי"]
                              : item.payments.CashMust[0] == "אשראי"
                                ? ["אשראי"]
                                : ["הון עצמי"]
                          }
                          paymentsVat={
                            item.payments.IsVat === "כן" ? true : false
                          }
                          paymentsSteps={true}
                          amount={Dashboard.numberWithCommas(
                            item.payments.PaymentAmount[0]
                          )}
                          PaymentDate={item.payments.PaymentDate[0]}
                          ApartmentNumber={item.payments.ApartmentNumber[0]}
                          ProjectName={item.payments.ProjectName[0]}
                          ProjectFile={item.payments.ProjectFile[0]}
                          Hash={item.payments.Hash[0]}
                        />
                      </Col>
                    ) : null
                  }
                  )}
              </Row>
            ) : (
              <div className="f20">אין דרישות תשלום משוייכות</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PaymentRequirements);
