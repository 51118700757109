import { Col, Icon, List, Select } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
/* STYLES */
import "./main.css";


const debounce = (func, delay = 450) => {
  let inDebounce;
  return (...args) => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const { Option } = Select;

/* STORES */
let MainStore;
let Dashboard;
@inject("mainStore", "dashboard")
@observer
class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      assetsList: [],
      pageNumber: 1,
    };
    MainStore = this.props.mainStore;
    Dashboard = this.props.dashboard;
  }

  componentDidMount() {
    this.getAseetsList();
  }

  handleChange(value) {
    MainStore.AddAssetToUser(value);
  }

  getAseetsList() {

    MainStore.getAllByModelAndByUser("assets", {}, this.state.pageNumber, 50, true).then((results) => {
      let assets = [];
      for (let i = 0; i < results.length; i++) {
        let asset = (
          <Option key={results[i].Hash} hash={results[i].Hash}>
            {`${results[i].City}: ${results[i].Street} - ${results[i].ApartmentNumber}`}
          </Option>
        );

        assets.push(asset);
      }
      this.setState({ data: assets });
    });
  }

  paginataeAssets(e) {
    e.persist();
    let assets = [];
    let target = e.target;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      MainStore.getAllByModelAndByUser(
        "assets",
        {},
        this.state.pageNumber,
        50,
        true
      ).then((results) => {
        for (let i = 0; i < results.length; i++) {
          let asset = (
            <Option key={results[i].Hash} hash={results[i].Hash}>
              {`${results[i].City}: ${results[i].Street} - ${results[i].ApartmentNumber}`}
            </Option>
          );

          assets.push(asset);
        }

        const assetsInObj = {};

        ([...this.state.data, ...assets]).forEach(element => {
          assetsInObj[element.key] = element;
        });

        this.setState({
          data: Object.values(assetsInObj),
          pageNumber: this.state.pageNumber + 1,
        });
      });
    }
  }

  remove(AssetHash) {
    MainStore.removeAssetFromUser(AssetHash);
  }

  onSearchDebounce = debounce((input) => {
    MainStore.getAllByModelAndByUser(
      "assets",
      {
        City: input,
        Street: input,
        ApartmentNumber: input,
      },
      0,
      50,
      true
    ).then((results) => {
      let assets = [];
      for (let i = 0; i < results.length; i++) {
        let asset = (
          <Option key={results[i].Hash} hash={results[i].Hash}>
            {`${results[i].City}: ${results[i].Street} - ${results[i].ApartmentNumber}`}
          </Option>
        );

        assets.push(asset);
      }

      const assetsInObj = {};

      (assets).forEach(element => {
        assetsInObj[element.key] = element;
      });

      this.setState({
        data: Object.values(assetsInObj),
        pageNumber: 0,
      });
    });
  })
  render() {
    let { items } = this.props;
    return (
      <div>
        <Select
          mode="multiple"
          placeholder="הוספת נכס"
          // defaultValue={MainStore.defaultValue}
          onChange={(e) => this.handleChange(e)}
          onPopupScroll={(e) => this.paginataeAssets(e)}
          style={{ width: "100%" }}
          optionFilterProp="children"
          onSearch={(input, option) => {
            this.onSearchDebounce(input);
          }
          }
        >
          {this.state.data}
        </Select>

        <List
          itemLayout="horizontal"
          dataSource={MainStore.assetsList}
          renderItem={(item) => (
            <List.Item>
              <Col span={22}>
                <b>{item.assets.ProjectName}</b> {item.assets.Street} -
                {item.assets.StreetNumber} דירה {item.assets.ApartmentNumber}
              </Col>

              <Col style={{ marginTop: "13px" }} span={2}>
                <Icon
                  onClick={(e) => this.remove(item.assets.AssetsHash)}
                  type="delete"
                  className="full marginCenter f3 red cursor"
                />
              </Col>
            </List.Item>
          )}
        />
      </div>
    );
  }
}
export default withRouter(ListView);
