import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Badge } from "antd";

/* STYLES */
import "./main.css";

/* STORES */
class PaymentBadge extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  /* EVENTS */
  render() {
    let { color, amount, text } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <Badge dot={true} color={color}></Badge>
          <div className="bold ltr">{amount}</div>
        </div>

        <div>{text}</div>
      </div>
    );
  }
}
export default withRouter(PaymentBadge);
