import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import ModalImage from "react-modal-image";
/* STYLES */
import "./main.css";

/* STORES */
let MainStore;
@inject("mainStore")
@observer
class GalleryScreen extends Component {
  constructor(props) {
    super(props);
    MainStore = this.props.mainStore;
  }

  imagesToObj(assetsImags) {
    const images = [];

    for (let i = 0; i < assetsImags.length; i++) {
      let image = {
        src: assetsImags[i].url,
        thumbnail: assetsImags[i].url,
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: assetsImags[i].file,
      };
      images.push(image);
    }
    return images;
  }

  extractFileName = (url) => {
    let decoded = "";
    if (url) {
      let file_name = url.split("/").pop();
      decoded = decodeURIComponent(file_name);
    }
    return decoded;
  };

  /* EVENTS */
  render() {
    let assetsImags = MainStore.selectedAssetData.assets.ProjectImages;
    let imageList = [];
    if (assetsImags !== undefined) {
      var sortedtImages = assetsImags,
        result = sortedtImages.reduce(function (r, a) {
          r[a.file] = r[a.file] || [];
          r[a.file].push(a);
          return r;
        }, Object.create(null));

      imageList = Object.entries(result).map(([key, value]) => {
        return (
          <div className="row-gallery full">
            <div className="bold black f4 marginRight20"> {key}</div>
            <div className="grid grid2">
              {value.map((image, i) =>
                image.url.includes(".pdf") ? (
                  <a
                    href={image.url}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-pdf" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(image.url)}
                    </div>
                  </a>
                ) : image.url.includes(".doc") ? (
                  <a
                    href={image.url}
                    style={{ height: "138px" }}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-word" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(image.url)}
                    </div>
                  </a>
                ) : image.url.includes(".csv") ? (
                  <a
                    href={image.url}
                    style={{ height: "138px" }}
                    className="textCenter black full show marginTop10 bold relative"
                  >
                    <Icon type="file-excel" className="file-icon" />
                    <div className="file-name">
                      {this.extractFileName(image.url)}
                    </div>
                  </a>
                ) : (
                  <ModalImage
                    className="image-grid"
                    small={image.url}
                    large={image.url}
                    alt="ניהול תמונה"
                  />
                )
              )}
            </div>
          </div>
        );
      });
    }

    return (
      <div className="Gallery conatiner-bottom">
        {assetsImags !== undefined && assetsImags.length > 0 ? (
          imageList
        ) : (
          <div className="textCenter bold black f1">
            אין תמונות מעודכנות לפרוייקט
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(GalleryScreen);
